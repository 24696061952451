
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Theme from '../repositories/data/Theme';

interface SelectableTheme extends Theme {
    hasChildren: boolean;
}

@Component
export default class SearchThemeSelector extends Vue {
    public themeSettingInput: string | null = null;
    public themeListFilter: string = '';
    public activeParent: string | null = null;

    @Prop({ type: Boolean, default: true }) public readonly isLoading!: boolean;
    @Prop({ type: Array, default: () => [] }) public readonly themes!: Theme[];
    @Prop({ type: String, default: '' }) public readonly fieldPrefix!: string;
    @Prop({ type: String, default: null }) public readonly lastValue!: string | null;

    public get parentThemes(): SelectableTheme[] {
        return this.themes
            .filter(t => t.parent === null)
            .map(t => ({ ...t, hasChildren: this.themes.some(o => o.parent && o.parent.id === t.id) }));
    }

    public get childThemes(): Theme[] {
        return this.themes.filter(t => t.parent && t.parent.id === this.activeParent);
    }

    // TODO: When search is figured out for this component, possibly need to restore this & related block in template
    // public get availableThemes(): Theme[] {
    //     const filter = this.themeListFilter.toLowerCase();
    //     return filter.length === 0
    //         ? this.themes
    //         : this.themes
    //               .filter(theme => theme.label && theme.label.toLowerCase().includes(filter))
    //               .sort(
    //                   (lhs, rhs) => lhs.label!.toLowerCase().indexOf(filter) - rhs.label!.toLowerCase().indexOf(filter),
    //               );
    // }

    public get currentThemeLabel(): string {
        const currentTheme = this.themes.find(theme => theme.id === this.themeSettingInput);
        return currentTheme && currentTheme.label
            ? (currentTheme.parent && currentTheme.parent.label ? currentTheme.parent.label + ': ' : '') +
                  currentTheme.label
            : this.$t('theme.themes').toString();
    }

    public get fieldName(): string {
        return 'ow-search-setting-' + this.fieldPrefix + '-theme';
    }

    @Watch('themeSettingInput')
    public onChangeThemeSetting(themeId: string | null, oldThemeId: string | null) {
        if (themeId !== oldThemeId) {
            this.$emit('theme-changed', themeId);
        }
    }

    @Watch('lastValue', { immediate: true })
    public onLastValueChanged(value: string | null) {
        this.themeSettingInput = value;
    }

    @Watch('activeParent', { immediate: true })
    public onActiveParentChanged(id: string | null) {
        if (id !== null) {
            const theme = this.parentThemes.find(t => t.id === id);
            if (theme) {
                if (!theme.hasChildren) {
                    this.themeSettingInput = id;
                } else if (this.themeSettingInput !== null) {
                    this.themeSettingInput = null;
                }
            }
        }
    }

    public selectActiveParent(id: string | null) {
        if (this.activeParent === id) {
            this.activeParent = null;
            this.themeSettingInput = null;
        } else {
            this.activeParent = id;
        }
    }

    public onThemeListFilterInput(event: any) {
        this.themeListFilter = event.target.value || '';
    }
}
