
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MediaFile from '@/repositories/data/MediaFile';

// tslint:disable-next-line:no-var-requires
const plyrIconUrl = require('@/assets/images/plyr.svg');

@Component
export default class MediaFileVideoPlayer extends Vue {
    @Prop({ type: Object, required: true }) public readonly file!: MediaFile;
    @Prop({ type: Boolean, required: false, default: false }) public readonly hasTheaterButton!: boolean;
    @Prop({ type: Boolean, required: false, default: false }) public readonly theaterModeEnabled!: boolean;
    @Prop({ type: Boolean, required: false, default: false }) public readonly autoplay!: boolean;
    @Prop({ type: Boolean, required: false, default: false }) public readonly disableFullscreen!: boolean;
    @Prop({ type: Boolean, required: false, default: false }) public readonly fullscreenMedia!: boolean;
    private didEnterFullscreen: boolean = false;

    public onPlayerPlayingCallback = () => {
        this.onStartedPlaying();
    };

    public onEndedPlayingCallback = () => {
        this.onEndedPlaying();
    };

    public onPlayerEnterFullscreenCallback = () => {
        this.onFullscreenEntered();
    };

    public onPlayerExitFullscreenCallback = () => {
        this.onFullscreenExited();
    };

    get options(): any {
        const controls: string[] = ['play-large', 'play', 'progress', 'settings', 'foobar'];
        if (!this.disableFullscreen) {
            controls.push('fullscreen');
        }
        return {
            controls,
            settings: ['speed', 'loop'],
            disableContextMenu: false,
            resetOnEnd: true,
            ratio: '16:9',
            speed: { selected: 1, options: [0.25, 0.5, 0.75, 1] }, // 0.25 doesn't work
            hideControls: false,
            iconUrl: plyrIconUrl,
        };
    }

    public get poster(): string | null {
        return `${process.env.VUE_APP_OW_MEDIA_ENDPOINT}/media/thumb/${this.file.id}`;
    }

    public get sources(): { src: string; type: string }[] {
        return [
            {
                src: `${process.env.VUE_APP_OW_MEDIA_ENDPOINT}/media/film/${this.file.id}`,
                type: 'video/mp4',
            },
        ];
    }

    public get player(): any {
        const plyr: any = this.$refs.plyr;
        return plyr.player;
    }

    public mounted(): void {
        this.$nextTick(() => {
            this.addTheaterButton();
            this.player.on('playing', this.onPlayerPlayingCallback);
            this.player.on('ended', this.onEndedPlayingCallback);
            this.player.on('enterfullscreen', this.onPlayerEnterFullscreenCallback);
            this.player.on('exitfullscreen', this.onPlayerExitFullscreenCallback);
        });
    }

    public updated(): void {
        this.$nextTick(() => {
            this.addTheaterButton();
            this.player.on('playing', this.onPlayerPlayingCallback);
            this.player.on('ended', this.onEndedPlayingCallback);
            this.player.on('enterfullscreen', this.onPlayerEnterFullscreenCallback);
            this.player.on('exitfullscreen', this.onPlayerExitFullscreenCallback);
        });
    }

    public beforeDestroy(): void {
        this.player.off('playing', this.onPlayerPlayingCallback);
        this.player.off('ended', this.onEndedPlayingCallback);
        this.player.off('enterfullscreen', this.onPlayerEnterFullscreenCallback);
        this.player.off('exitfullscreen', this.onPlayerExitFullscreenCallback);
    }

    @Watch('sources')
    private onSourcesChanged() {
        this.player.source = {
            type: 'video',
            sources: this.sources,
            poster: this.poster,
        };
        if (this.autoplay) {
            this.player.play();
        }
    }

    @Watch('autoplay')
    private onAutoplayChanged(value: boolean) {
        if (value) {
            this.player.play();
        }
    }

    @Watch('fullscreenMedia', { immediate: true })
    private onFullscreenMediaChanged(value: boolean): void {
        if (this.didEnterFullscreen && !value) {
            this.player.fullscreen.exit();
        }
    }

    private addTheaterButton(): void {
        if (!this.hasTheaterButton) {
            return;
        }

        const plyr = this.$refs.plyr as Vue;
        const fsEl = plyr.$el.querySelector('.plyr__control[data-plyr="fullscreen"]');
        const existingEl = plyr.$el.querySelector('.plyr__control[data-plyr="theater"]');
        if (fsEl != null && fsEl.parentElement != null && existingEl == null) {
            const button = document.createElement('button');
            button.setAttribute('class', 'plyr__controls__item plyr__control');
            button.setAttribute('type', 'button');
            button.setAttribute('data-plyr', 'theater');

            button.innerHTML = `<svg class="icon--pressed" role="presentation" focusable="false">
                        <use xlink:href="${plyrIconUrl}#plyr-theater"></use>
                    </svg>
                    <svg class="icon--not-pressed" role="presentation" focusable="false">
                        <use xlink:href="${plyrIconUrl}#plyr-theater"></use>
                    </svg>`;

            button.addEventListener('click', this.onTheaterToggle);

            fsEl.parentElement.insertBefore(button, fsEl);
        }
    }

    private onTheaterToggle() {
        this.$emit('theaterToggle');
    }

    private onStartedPlaying() {
        this.$emit('startedPlaying');
    }

    private onEndedPlaying() {
        // Warning: as per plyr documentation, does not fire if playback started through autoplay
        // (However, we have a custom approach to 'autoplay' which should not suffer from this)
        this.$emit('endedPlaying');
    }

    private onFullscreenEntered() {
        this.didEnterFullscreen = true;
        this.$emit('update:fullscreenMedia', true);
    }

    private onFullscreenExited() {
        if (this.didEnterFullscreen) {
            this.didEnterFullscreen = false;
            this.$emit('update:fullscreenMedia', false);
        }
    }
}
