
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VariantCategory from '@/repositories/data/VariantCategory';

@Component
export default class SearchVariantCategorySelector extends Vue {
    public variantCategorySettingInput: string = '0';

    @Prop({ type: Boolean, default: true }) public readonly isLoading!: boolean;
    @Prop({ type: Array, default: () => [] }) public readonly availableVariantCategories!: VariantCategory[];
    @Prop({ type: String, default: '' }) public readonly fieldPrefix!: string;
    @Prop({ type: String, default: '' }) public readonly lastValue!: string;

    public get currentVariantCategoryLabel(): string {
        const currentCategory = this.availableVariantCategories.find(
            category => category.id === this.variantCategorySettingInput,
        );
        return currentCategory && currentCategory.label ? currentCategory.label : this.$t('loading').toString();
    }

    public get fieldName(): string {
        return 'ow-search-setting-' + this.fieldPrefix + '-variant-category';
    }

    @Watch('variantCategorySettingInput')
    public onChangeVariantCategorySetting(variantCategoryId: string, oldVariantCategoryId: string) {
        if (variantCategoryId !== oldVariantCategoryId) {
            this.$emit('variant-category-changed', variantCategoryId);
        }
    }

    @Watch('lastValue', { immediate: true })
    public onLastValueChanged(value: string) {
        this.variantCategorySettingInput = value;
    }
}
