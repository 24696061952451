import Variant from '@/repositories/data/Variant';
import Phrase from '@/repositories/data/Phrase';

export enum UsageExampleType {
    AlsoIn = 'AlsoIn',
    ForExample = 'ForExample',
    NotIn = 'NotIn',
}

export default interface UsageExample {
    glossVariantReference?: Variant | null;
    phrase?: Phrase | null;
    prefix?: UsageExampleType | null;
    priority?: number | null;
    text?: string | null;
}
