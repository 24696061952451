import { BehaviorSubject, combineLatest, defer, from, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import RawRequestMiddleware from '@/repositories/api/RawRequestMiddleware';
import RawResponseMiddleware from '@/repositories/api/RawResponseMiddleware';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthManager from '@/util/auth/AuthManager';
import User from '@/repositories/data/User';
import UserRepository from '@/repositories/UserRepository';
import Resource from '@/repositories/Resource';

export default class GlobalViewModel implements RawRequestMiddleware, RawResponseMiddleware {
    private readonly _authManager: AuthManager;
    private readonly _userRepository: UserRepository;
    private _currentUser: User | null = null;
    private readonly mobileFullscreenModalOpenSubject: BehaviorSubject<boolean>;
    private readonly lightBoxOpenSubject: BehaviorSubject<boolean>;
    private readonly errorActiveSubject: BehaviorSubject<boolean>;
    private readonly warnGuestActiveSubject: BehaviorSubject<boolean>;
    private baseClippedSubscription: Subscription;
    private reportedUserIsGuest = false;

    constructor(authManager: AuthManager, userRepository: UserRepository) {
        this._authManager = authManager;
        this._userRepository = userRepository;
        this.errorActiveSubject = new BehaviorSubject<boolean>(false);
        this.warnGuestActiveSubject = new BehaviorSubject<boolean>(false);
        this.mobileFullscreenModalOpenSubject = new BehaviorSubject<boolean>(false);
        this.lightBoxOpenSubject = new BehaviorSubject<boolean>(false);
        this.baseClippedSubscription = combineLatest([this.mobileFullscreenModalOpenSubject, this.lightBoxOpenSubject])
            .pipe(map(([mobileFullscreen, lightBox]) => mobileFullscreen || lightBox))
            .subscribe(active => {
                const html = document.getElementById('base');
                if (html) {
                    if (active) {
                        html.classList.add('is-clipped');
                    } else {
                        html.classList.remove('is-clipped');
                    }
                }
            });
    }

    public beginUserLogin(): void {
        this._authManager.startRedirectLogin();
    }

    public beginUserLogout(): void {
        this._authManager.startRedirectLogout();
    }

    public get isUserLoaded(): boolean {
        return this._currentUser !== null;
    }

    public get userObservable(): Observable<Resource<User>> {
        return defer(() => {
            return this._currentUser
                ? from([Resource.success(this._currentUser)])
                : this._userRepository.findCurrent().pipe(
                      tap(userResource => {
                          if (userResource.isSuccess) {
                              this._currentUser = userResource.data;
                              if (!this.reportedUserIsGuest && !this._currentUser) {
                                  this.reportedUserIsGuest = true;
                                  this.warnGuestActiveSubject.next(true);
                              } else {
                                  this.reportedUserIsGuest = false;
                              }
                          } else {
                              this._currentUser = null;
                          }
                      }),
                  );
        });
    }

    get verifiedUser(): Observable<User | null> {
        return this.userObservable.pipe(
            filter(t => !t.isLoading),
            map(t => (t.isSuccess && t.data ? t.data : null)),
        );
    }

    get isErrorActive(): Observable<boolean> {
        return this.errorActiveSubject.asObservable();
    }

    public reportError(): void {
        this.errorActiveSubject.next(true);
    }

    public dismissError(): void {
        this.errorActiveSubject.next(false);
    }

    get isGuestWarningActive(): Observable<boolean> {
        return this.warnGuestActiveSubject.asObservable();
    }

    public dismissGuestWarning(): void {
        this.warnGuestActiveSubject.next(false);
    }

    public beforeRequestInterceptor(): ((request: AxiosRequestConfig) => AxiosRequestConfig) | undefined {
        return config => {
            this.dismissError();
            return config;
        };
    }

    public dataInterceptor(): ((value: AxiosResponse<any>) => AxiosResponse<any>) | undefined {
        return value => {
            if (value.data.errors) {
                this.reportError();
            }
            return value;
        };
    }

    public errorInterceptor(): ((error: any) => any) | undefined {
        return error => {
            if (error.response && error.response.status === 401) {
                // TODO: review this
                this._authManager.startRedirectLogin();
            } else if (!error.response || error.response.status >= 500) {
                this.reportError();
            }
            return Promise.reject(error);
        };
    }

    public get isMobileFullscreenModalOpen(): boolean {
        return this.mobileFullscreenModalOpenSubject.getValue();
    }

    public set isMobileFullscreenModalOpen(value: boolean) {
        this.mobileFullscreenModalOpenSubject.next(value);
    }

    public get isLightBoxOpen(): boolean {
        return this.lightBoxOpenSubject.getValue();
    }

    public set isLightBoxOpen(value: boolean) {
        this.lightBoxOpenSubject.next(value);
    }
}
