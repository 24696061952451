import GraphQlApi from '@/repositories/api/GraphQlApi';
import { Observable } from 'rxjs';
import Resource from '@/repositories/Resource';
import User from '@/repositories/data/User';
import networkBoundResource from '@/repositories/networkBoundResource';
import unwrapGraphQlResponse from '@/repositories/unwrapGraphQlResponse';

export default class UserRepository {
    private readonly api: GraphQlApi;

    constructor(api: GraphQlApi) {
        this.api = api;
    }

    public findCurrent(): Observable<Resource<User>> {
        const observable = this.api.getUser();
        return networkBoundResource(unwrapGraphQlResponse(observable));
    }
}
