
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignLocation from '@/repositories/data/SignLocation';

@Component
export default class SignLocationImage extends Vue {
    @Prop({ type: Object, required: true }) public readonly location!: SignLocation;
    @Prop({ type: Number, required: false, default: 217 }) public readonly width!: number;
    @Prop({ type: Number, required: false, default: 174 }) public readonly height!: number;

    public onHeadSelected(): void {
        this.$emit('location-selected', '1');
    }

    public onArmsSelected(): void {
        this.$emit('location-selected', '2');
    }

    public onBodySelected(): void {
        this.$emit('location-selected', '3');
    }

    public onInFrontSelected(): void {
        this.$emit('location-selected', '4');
    }
}
