
import { Component, Prop, Vue } from 'vue-property-decorator';
import MediaFile from '../repositories/data/MediaFile';

@Component
export default class MediaFileThumbnail extends Vue {
    @Prop({ type: Object, required: false, default: null })
    public readonly file!: MediaFile | null;

    @Prop({ type: String, required: false, default: '' })
    public readonly caption!: string;

    @Prop({ type: Boolean, required: false, default: false })
    public readonly imitatePlayer!: boolean;

    public get thumbnailUrl(): string | null {
        return this.file ? `${process.env.VUE_APP_OW_MEDIA_ENDPOINT}/media/thumb/${this.file.id}` : null;
    }
}
