
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignPropertySelector from '@/components/SignPropertySelector.vue';
import SignShape from '@/repositories/data/SignShape';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import SignGesture from '@/repositories/data/SignGesture';
import SignLocation from '@/repositories/data/SignLocation';
import SignOralComponent from '@/repositories/data/SignOralComponent';
import SignPropertySelection from '@/repositories/data/SignPropertySelection';
import { debounceTime, map, skip } from 'rxjs/operators';

@Component({
    components: {
        SignPropertySelector,
    },
})
export default class SearchSignParametersSelector extends Vue {
    @Prop({ type: Boolean, default: true }) public readonly isLoading!: boolean;
    @Prop({ type: Array, default: () => [] }) public readonly signShapes!: SignShape[];
    @Prop({ type: Array, default: () => [] }) public readonly signGestures!: SignGesture[];
    @Prop({ type: Array, default: () => [] }) public readonly signLocations!: SignLocation[];
    @Prop({ type: Array, default: () => [] }) public readonly signOralComponents!: SignOralComponent[];
    @Prop({ type: Object, required: true }) public readonly lastSelection!: SignPropertySelection;
    @Prop({ type: String, default: '' }) public readonly fieldPrefix!: string;
    @Prop({ type: Boolean, default: true }) public readonly visible!: boolean;

    private shapeLeftSubject = new BehaviorSubject<string>('');
    private shapeRightSubject = new BehaviorSubject<string>('');
    private locationSubject = new BehaviorSubject<string>('');
    private gestureLeftSubject = new BehaviorSubject<string>('');
    private gestureRightSubject = new BehaviorSubject<string>('');
    private oralComponentSubject = new BehaviorSubject<string>('');
    private signPropertiesSubscription = new Subscription();

    public created(): void {
        this.signPropertiesSubscription = combineLatest([
            this.shapeLeftSubject,
            this.shapeRightSubject,
            this.locationSubject,
            this.gestureLeftSubject,
            this.gestureRightSubject,
            this.oralComponentSubject,
        ])
            .pipe(
                skip(1),
                debounceTime(10),
                map(
                    ([
                        shapeLeft,
                        shapeRight,
                        location,
                        gestureLeft,
                        gestureRight,
                        oralComponent,
                    ]): SignPropertySelection => ({
                        shapeLeft,
                        shapeRight,
                        location,
                        gestureLeft,
                        gestureRight,
                        oralComponent,
                    }),
                ),
            )
            .subscribe(selection => {
                this.$emit('signPropertySelection', selection);
            });
    }

    public beforeDestroy(): void {
        this.signPropertiesSubscription.unsubscribe();
    }

    public onShapeLeftChanged(value: string): void {
        this.shapeLeftSubject.next(value);
    }

    public onShapeRightChanged(value: string): void {
        this.shapeRightSubject.next(value);
    }

    public onLocationChanged(value: string): void {
        this.locationSubject.next(value);
    }

    public onGestureLeftChanged(value: string): void {
        this.gestureLeftSubject.next(value);
    }

    public onGestureRightChanged(value: string): void {
        this.gestureRightSubject.next(value);
    }

    public onOralComponentChanged(value: string): void {
        this.oralComponentSubject.next(value);
    }
}
