
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import GlobalViewModel from '@/state/GlobalViewModel';
import GlossRepository from '@/repositories/GlossRepository';
import GeneralScaffold from '@/components/GeneralScaffold.vue';
import FormattedGlossName from '@/components/FormattedGlossName.vue';
import { BehaviorSubject, combineLatest, of as observableOf, Subscription, zip } from 'rxjs';
import Resource from '@/repositories/Resource';
import Gloss from '@/repositories/data/Gloss';
import Variant from '@/repositories/data/Variant';
import GlossVariantUsageExampleItem from '@/components/GlossVariantUsageExampleItem.vue';
import SignLocationImage from '@/components/SignLocationImage.vue';
import SignShapeImage from '@/components/SignShapeImage.vue';
import MediaFile from '@/repositories/data/MediaFile';
import UsageExample from '@/repositories/data/UsageExample';
import MediaFileVideoPlayer from '@/components/MediaFileVideoPlayer.vue';
import VariantCategory from '@/repositories/data/VariantCategory';
import { RawLocation, Route } from 'vue-router';
import GlossVariantCategorySelector from '@/components/GlossVariantCategorySelector.vue';
import SignDefinition from '@/repositories/data/SignDefinition';
import SeeAlsoSection from '@/components/SeeAlsoSection.vue';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import InformationBulb from '@/components/InformationBulb.vue';
import GrammarInfoHighlighter from '@/components/GrammarInfoHighlighter.vue';
import MediaFileThumbnail from '@/components/MediaFileThumbnail.vue';
import LightBoxVideoPlayer from '@/components/LightBoxVideoPlayer.vue';
import ExplanationItem from '@/components/ExplanationItem.vue';
import Explanation from '@/repositories/data/Explanation';

type ID = number | string;

const variantCompare = (a: Variant, b: Variant) => {
    // Ignore if priority of a or b is not an int
    if (
        !a.category ||
        !b.category ||
        (!a.category.priority && a.category.priority !== 0) ||
        (!b.category.priority && b.category.priority !== 0)
    ) {
        return 0;
    }

    if (a.category.priority === b.category.priority) {
        return 0;
    }

    return a.category.priority < b.category.priority ? -1 : 1;
};

@Component({
    components: {
        LightBoxVideoPlayer,
        MediaFileThumbnail,
        SeeAlsoSection,
        GlossVariantCategorySelector,
        SignShapeImage,
        SignLocationImage,
        GlossVariantUsageExampleItem,
        ExplanationItem,
        FormattedGlossName,
        GeneralScaffold,
        MediaFileVideoPlayer,
        InformationBulb,
        GrammarInfoHighlighter,
    },
    beforeRouteLeave(to: Route, from: Route, next: (to?: any) => void): void {
        (this as GlossDetailsScreen).beforeRouteChanged(to, from, next);
    },
    beforeRouteUpdate(to: Route, from: Route, next: (to?: any) => void): void {
        (this as GlossDetailsScreen).beforeRouteChanged(to, from, next);
    },
})
export default class GlossDetailsScreen extends Vue {
    public gloss: Gloss | null = null;
    public lightBoxFile: MediaFile | null = null;
    public fullscreenMedia: boolean = false;
    public loading: boolean = true;
    public rightSideZoomed: boolean = false;
    public activeExplanationFilm: MediaFile | null = null;
    public autoplayActiveExplanationFilm: boolean = false;
    public activeUsageFilm: MediaFile | null = null;
    public autoplayActiveUsageFilm: boolean = false;
    public expandedOtherSignDefinitionFilmId: string | null = null;

    private moduleSubject = new BehaviorSubject<string | null>(null);
    private targetGlossIdSubject = new BehaviorSubject<string | null>(null);
    private glossSubscription = new Subscription();
    private amLoggedInSubject = new BehaviorSubject<boolean>(false);
    private amLoggedInSubscription = new Subscription();
    private variantCategoryId: ID | null = null;
    @Inject() private readonly globalViewModel!: GlobalViewModel;
    @Inject() private readonly glossRepository!: GlossRepository;

    public created(): void {
        this.updateFromRouteQuery(true);
        this.glossSubscription = combineLatest([
            this.globalViewModel.verifiedUser,
            this.targetGlossIdSubject,
            this.moduleSubject.pipe(distinctUntilChanged()),
        ])
            .pipe(
                switchMap(([user, id, moduleId]) =>
                    id === null
                        ? observableOf(Resource.loading<Gloss>(null))
                        : this.glossRepository.findGlossById(id, moduleId),
                ),
            )
            .subscribe(glossResource => {
                this.loading = glossResource.isLoading;
                if (glossResource.isSuccess && glossResource.hasData()) {
                    const gloss = glossResource.data;
                    if (gloss.variants) {
                        // sort variant by category id
                        gloss.variants.sort(variantCompare);
                    }
                    this.gloss = gloss;
                } else {
                    this.gloss = null;
                }
            });
        this.amLoggedInSubscription = this.globalViewModel.verifiedUser
            .pipe(map(user => user !== null))
            .subscribe(this.amLoggedInSubject);
        this.fetchData();
    }

    public beforeDestroy(): void {
        this.amLoggedInSubscription.unsubscribe();
        this.glossSubscription.unsubscribe();
    }

    get keywords(): string[] {
        return this.gloss !== null
            ? this.gloss.keywords.filter(keyword => {
                  return this.gloss !== null && keyword.toLowerCase() !== this.gloss.name.toLowerCase();
              })
            : [];
    }

    get variant(): Variant | null {
        if (this.gloss === null || !this.gloss.variants || this.gloss.variants.length === 0) {
            return null;
        }

        // return variant with highest category priority
        if (this.variantCategoryId === null) {
            return this.gloss.variants[0];
        }

        return (
            this.gloss.variants.find(variant => {
                return typeof variant.category !== 'undefined' && variant.category.id === this.variantCategoryId;
            }) || null
        );
    }

    get lexiconFilm(): MediaFile | null {
        const variant = this.variant;
        if (variant && variant.signDefinitions && variant.signDefinitions.length > 0) {
            const signDefinition = variant.signDefinitions[0];
            if (signDefinition.film) {
                return signDefinition.film;
            }
        }
        return null;
    }

    get variantCategory(): VariantCategory | null {
        return this.variant && this.variant.category ? this.variant.category : null;
    }

    get variantCategories(): VariantCategory[] {
        if (!this.gloss || !this.gloss.variants) {
            return [];
        }

        return this.gloss.variants.filter(v => v.category).map(v => v.category!);
    }

    get isSimpleExplanations(): boolean {
        return (
            (this.gloss &&
                this.gloss.explanations &&
                this.gloss.explanations.length > 0 &&
                !this.gloss.explanations.some(t => t.film)) ||
            false
        );
    }

    get isMediaExplanations(): boolean {
        return (
            (this.gloss &&
                this.gloss.explanations &&
                this.gloss.explanations.length > 0 &&
                this.gloss.explanations.some(t => t.film)) ||
            false
        );
    }

    get sortedExplanations(): Explanation[] {
        const explanations = (this.gloss && this.gloss.explanations && this.gloss.explanations) || [];
        const sorted = explanations
            .map((entry, index) => ({ index, priority: entry.priority ? entry.priority : 0 }))
            .sort((a, b) => a.priority - b.priority);
        return sorted.map(entry => explanations[entry.index]);
    }

    get sortedUsageExamples(): UsageExample[] {
        if (this.variant && this.variant.usageExamples) {
            const usageExamples = this.variant.usageExamples;
            const sorted = usageExamples
                .map((entry, index) => ({ index, priority: entry.priority ? entry.priority : 0 }))
                .sort((a, b) => a.priority - b.priority);
            return sorted.map(entry => usageExamples[entry.index]);
        }
        return [];
    }

    get otherSignDefinitionsWithFilm(): SignDefinition[] {
        if (!this.gloss || !this.variant || !this.variant.signDefinitions) {
            return [];
        }

        const lexiconFilmId = this.lexiconFilm ? this.lexiconFilm.id : null;

        return this.variant.signDefinitions.filter(ref => ref.film && ref.film.id && ref.film.id !== lexiconFilmId);
    }

    get seeAlsoGlosses(): Gloss[] {
        if (!this.variant || !this.variant.seeAlsoReferences || !this.variant.seeAlsoReferences.length) {
            return [];
        }

        return this.variant.seeAlsoReferences.filter(ref => ref.gloss && ref.gloss.id).map(ref => ref.gloss!);
    }

    @Watch('gloss')
    public onGlossChange(gloss: Gloss | null): void {
        const explanationsWithFilm = gloss && gloss.explanations ? gloss.explanations.filter(t => t.film) : [];
        this.autoplayActiveExplanationFilm = false;
        if (explanationsWithFilm.length) {
            const [firstExplanationWithFilm] = explanationsWithFilm;
            this.activeExplanationFilm =
                firstExplanationWithFilm && firstExplanationWithFilm.film ? firstExplanationWithFilm.film : null;
        } else {
            this.activeExplanationFilm = null;
        }
    }

    @Watch('variant')
    public onVariantChange(variant: Variant | null): void {
        const examplesWithFilm =
            variant && variant.usageExamples ? variant.usageExamples.filter(ue => ue.phrase && ue.phrase.film) : [];
        this.autoplayActiveUsageFilm = false;
        if (examplesWithFilm.length) {
            const [firstExampleWithFilm] = examplesWithFilm;
            // ugly code because TS is stupid
            this.activeUsageFilm =
                firstExampleWithFilm && firstExampleWithFilm.phrase && firstExampleWithFilm.phrase.film
                    ? firstExampleWithFilm.phrase.film
                    : null;
        } else {
            this.activeUsageFilm = null;
        }
    }

    public getMediaImageUrl(media: MediaFile): string {
        return `${process.env.VUE_APP_OW_MEDIA_ENDPOINT}/media/img/${media.id}`;
    }

    public onExplanationSelect(explanation: Explanation): void {
        if (explanation.film) {
            this.activeExplanationFilm = explanation.film;
            this.autoplayActiveExplanationFilm = true;
        }
    }

    public onUsageExampleSelect(example: UsageExample): void {
        if (example.phrase && example.phrase.film) {
            this.activeUsageFilm = example.phrase.film;
            this.autoplayActiveUsageFilm = true;
        }
    }

    public onReferencedGlossVariantSelected(variant: Variant): void {
        if (variant.gloss && variant.gloss.id && variant.category && variant.category.id) {
            this.$router.push({
                name: 'gloss-details',
                params: {
                    id: variant.gloss.id,
                    category_id: variant.category.id,
                },
            } as RawLocation);
        }
    }

    public onVariantSelect(category: VariantCategory): void {
        if (this.gloss && this.gloss.id) {
            this.$router.push({
                name: 'gloss-details',
                params: {
                    id: this.gloss.id,
                    category_id: category.id,
                },
            } as RawLocation);
        }
    }

    public onUpdateZoom(target: string, toggle: boolean) {
        if (target === 'usage' || target === 'explanation') {
            this.rightSideZoomed = toggle ? !this.rightSideZoomed : true;
        } else {
            this.rightSideZoomed = false;
        }
    }

    public onExpandOtherSignDefinition(filmId: string | null, toggle: boolean) {
        this.onUpdateZoom('lexicon', toggle);
        if (toggle && this.expandedOtherSignDefinitionFilmId === filmId) {
            this.expandedOtherSignDefinitionFilmId = null;
        } else {
            this.expandedOtherSignDefinitionFilmId = filmId;
        }
    }

    public onShowInLightBox(file: MediaFile | null): void {
        this.lightBoxFile = file;
    }

    @Watch('$route')
    public onRouteChange(to: Route): void {
        this.updateFromRouteQuery(false);
        const currentGlossId = this.gloss && this.gloss.id ? this.gloss.id : null;
        const targetGlossId = to.params.id || null;

        this.variantCategoryId = this.$route.params.category_id || null;

        if (currentGlossId !== targetGlossId) {
            this.autoplayActiveExplanationFilm = false;
            this.autoplayActiveUsageFilm = false;
            this.fetchData();
        }
    }

    public fetchData(): void {
        const id = this.$route.params.id;
        this.targetGlossIdSubject.next(id);
    }

    public onSearchSubmit(search: string | null): void {
        if (search) {
            this.$router.push({ name: 'search', query: { q: search } });
        }
    }

    public onToggleFavoured() {
        if (this.gloss) {
            this.gloss.favoured = !this.gloss.favoured;
            // We actually don't care to much about the result, we can't do much about it anyway
            zip(
                this.globalViewModel.verifiedUser.pipe(first()),
                observableOf(this.gloss.id),
                observableOf(this.gloss.favoured),
            )
                .pipe(switchMap(([user, id, favourite]) => this.glossRepository.setAsFavourite(id, favourite)))
                .subscribe();
        }
    }

    private updateFromRouteQuery(initial: boolean): void {
        if (this.$route.query.hasOwnProperty('l')) {
            this.moduleSubject.next(typeof this.$route.query.l === 'string' ? this.$route.query.l : null);
        }
        if (initial) {
            this.variantCategoryId = this.$route.params.category_id || null;
        }
    }

    // noinspection JSUnusedLocalSymbols
    private beforeRouteChanged(to: Route, from: Route, next: (to?: any) => void): void {
        // @ts-ignore
        const back = window.popStateDetected;
        if (back && this.lightBoxFile !== null) {
            this.lightBoxFile = null;
            next(false);
        } else if (back && this.fullscreenMedia) {
            this.fullscreenMedia = false;
            next(false);
        } else {
            next();
        }
    }
}
