
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import GlobalViewModel from '@/state/GlobalViewModel';
import Resource from '@/repositories/Resource';
import User from '@/repositories/data/User';
import { Subscription } from 'rxjs';

@Component
export default class UserDisplay extends Vue {
    public userResource: Resource<User> = Resource.loading<User>(null);
    @Prop({ default: false }) public readonly compactOnMobile!: boolean;

    @Inject() private readonly globalViewModel!: GlobalViewModel;
    private subscription = new Subscription();

    public created(): void {
        this.subscription = this.globalViewModel.userObservable.subscribe(currentUser => {
            this.userResource = currentUser;
        });
    }

    public beforeDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onNavigateToHome(): void {
        this.$router.push({ name: 'home' });
    }

    public onNavigateToMyLists(): void {
        this.$router.push({ name: 'lists' });
    }

    public onNavigateToMyFavourites(): void {
        this.$router.push({ name: 'favourites' });
    }

    public onNavigateToLogin(): void {
        this.globalViewModel.beginUserLogin();
    }

    public onLogout(): void {
        this.globalViewModel.beginUserLogout();
    }
}
