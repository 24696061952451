
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignShape from '@/repositories/data/SignShape';

const sideValidator = (value: string): boolean => ['left', 'right'].indexOf(value) !== -1;

@Component
export default class SignShapeImage extends Vue {
    @Prop({ type: Object, required: true }) public readonly signShape!: SignShape;
    @Prop({ type: String, required: true, validator: sideValidator }) public readonly side!: string;

    public get src(): string | null {
        return require(`../assets/images/sign-shapes/${this.signShape.id}-${this.side === 'left' ? 'l' : 'r'}.png`);
    }

    public get alt(): string | null {
        return this.signShape.labelNl || null;
    }
}
