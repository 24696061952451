
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import MediaFileVideoPlayer from '@/components/MediaFileVideoPlayer.vue';
import MediaFile from '../repositories/data/MediaFile';
import GlobalViewModel from '@/state/GlobalViewModel';

@Component({
    components: {
        MediaFileVideoPlayer,
    },
})
export default class LightBoxVideoPlayer extends Vue {
    @Inject()
    private readonly globalViewModel!: GlobalViewModel;

    @Prop({ type: Object, required: false, default: null })
    private readonly file!: MediaFile | null;

    @Watch('file')
    public onFileChanged(value: MediaFile | null): void {
        this.globalViewModel.isLightBoxOpen = value !== null;
    }

    public onCloseLightBox(): void {
        this.$emit('close');
    }
}
