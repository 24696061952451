
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import UserDisplay from '@/components/UserDisplay.vue';
import GlossTextSearchInput from '@/components/GlossTextSearchInput.vue';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import GlossRepository from '@/repositories/GlossRepository';
import Resource, { ResourceStatus } from '@/repositories/Resource';
import GlobalViewModel from '@/state/GlobalViewModel';
import ErrorReportsFrame from '@/components/ErrorReportsFrame.vue';
import { SortDirection } from '@/repositories/data/Pageable';
import SignApiPage from '@/repositories/data/SignApiPage';

@Component({
    components: {
        ErrorReportsFrame,
        GlossTextSearchInput,
        UserDisplay,
    },
})
export default class GeneralScaffold extends Vue {
    public searchKeywordsSetting = 'starts_with';
    public searchSubject = new BehaviorSubject<string>('');
    public searchKeywordsSettingSubject = new BehaviorSubject<string>(this.searchKeywordsSetting);
    public themeIdSubject = new BehaviorSubject<string | null>(null);
    public variantCategoryIdSubject = new BehaviorSubject<string>('0');
    public moduleSubject = new BehaviorSubject<string | null>(null);
    public glossPageResourceSubject = new BehaviorSubject<Resource<SignApiPage<GlossSearchResult>>>(
        Resource.success<SignApiPage<GlossSearchResult>>(null),
    );
    public searchResults: GlossSearchResult[] = [];
    public searchLoading: boolean = false;

    @Prop({ default: null }) public readonly filterThemeId!: string | null;
    @Prop({ default: '0' }) public readonly filterVariantCategoryId!: string;
    @Prop({ default: true }) public readonly showSearchInput!: boolean;

    @Inject() private readonly globalViewModel!: GlobalViewModel;
    @Inject() private readonly glossRepository!: GlossRepository;

    private searchSubscription = new Subscription();
    private searchKeywordSettingSubscription = new Subscription();
    private lastRouteSearchValue = '';

    public get latestTopResult(): GlossSearchResult | null {
        if (this.searchResults.length > 0) {
            return this.searchResults[0];
        }
        return null;
    }

    public created(): void {
        this.updateFromRouteQuery();
        this.searchKeywordSettingSubscription = this.searchKeywordsSettingSubject.subscribe(value =>
            this.$emit('search-keyword-setting', value),
        );
        this.searchSubscription = combineLatest([
            this.globalViewModel.verifiedUser,
            this.moduleSubject.pipe(distinctUntilChanged()),
            combineLatest([
                this.searchSubject.pipe(distinctUntilChanged(), debounceTime(600)),
                this.searchKeywordsSettingSubject,
                this.themeIdSubject,
                this.variantCategoryIdSubject,
            ]).pipe(
                map(([search, searchKeywordSetting, themeId, variantCategoryId]) => ({
                    keyword: search,
                    anywhere: searchKeywordSetting !== 'starts_with',
                    themeId,
                    variantCategoryId,
                })),
            ),
        ])
            .pipe(
                switchMap(([user, moduleId, filter]) =>
                    this.glossRepository.findGlossesByKeyword({
                        ...filter,
                        moduleId,
                        pageable: {
                            page: 0,
                            size: 10,
                            sort: [{ property: 'name', direction: SortDirection.ASC }],
                        },
                    }),
                ),
            )
            .subscribe((glossPageResource: Resource<SignApiPage<GlossSearchResult>>) => {
                this.glossPageResourceSubject.next(glossPageResource);
                if (glossPageResource.status === ResourceStatus.SUCCESS) {
                    if (glossPageResource.data !== null) {
                        this.searchResults = [...glossPageResource.data.content];
                    } else {
                        this.searchResults = [];
                    }
                }
                this.searchLoading = glossPageResource.status === ResourceStatus.LOADING;
            });
    }

    public beforeDestroy(): void {
        this.searchKeywordSettingSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
    }

    public onLogoClicked(): void {
        this.$router.push({ name: 'home' });
    }

    @Watch('searchKeywordsSetting')
    public onSearchKeywordsSettingChanged(setting: string) {
        this.searchKeywordsSettingSubject.next(setting);
    }

    @Watch('filterThemeId')
    public onFilterThemeIdChanged(value: string | null) {
        this.themeIdSubject.next(value);
    }

    @Watch('filterVariantCategoryId')
    public onFilterVariantCategoryIdChanged(value: string) {
        this.variantCategoryIdSubject.next(value);
    }

    public onSearchInput(value: string): void {
        const search = value.trim();
        if (this.searchSubject.getValue() !== search) {
            this.searchSubject.next(search);
        }
    }

    public onSearchSubmit(): void {
        this.$emit('search-text-query', this.searchSubject.getValue());
    }

    @Watch('latestTopResult', { immediate: true })
    public onLatestTopResultChanged(): void {
        this.$emit('latest-top-result-changed', this.latestTopResult);
    }

    @Watch('$route')
    public onRouteChanged() {
        this.updateFromRouteQuery();
    }

    private updateFromRouteQuery(): void {
        if (this.$route.query.hasOwnProperty('q')) {
            const value = this.$route.query.q as string;
            if (value !== this.lastRouteSearchValue) {
                this.lastRouteSearchValue = value;
                this.searchSubject.next(value);
            }
        }
        if (this.$route.query.hasOwnProperty('l')) {
            this.moduleSubject.next(typeof this.$route.query.l === 'string' ? this.$route.query.l : null);
        }
    }
}
