
import { Component, Prop, Vue } from 'vue-property-decorator';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import FormattedGlossName from '@/components/FormattedGlossName.vue';
import MediaFileThumbnail from '@/components/MediaFileThumbnail.vue';
import InformationBulb from '@/components/InformationBulb.vue';

@Component({
    components: {
        FormattedGlossName,
        MediaFileThumbnail,
        InformationBulb,
    },
})
export default class SeeAlsoSection extends Vue {
    @Prop({ type: String, required: false, default: null }) public readonly title!: string | null;
    @Prop({ type: Array, required: true }) public readonly glosses!: GlossSearchResult[];
    @Prop({ type: Boolean, required: false, default: false }) public readonly hideHint!: string | null;
}
