
import { Component, Prop, Vue } from 'vue-property-decorator';

const termsMap: { [key: string]: string } = {
    invariant: 'invariant werkwoord',
    directioneel: 'directioneel werkwoord',
    'locatief worden gebruikt': 'locatief gebruikt',
    locatief: 'locatief werkwoord',
    hulpwerkwoord: 'hulpwerkwoord op',
    'orale component': 'orale component',
    'gesproken component': 'gesproken component',
    samengesteld: 'samenstelling',
    'duratief aspect': 'duratief aspect',
    'frequentatief aspect': 'frequentatief aspect',
    classifier: 'classifier',
};

interface TextElement {
    text: string;
    highlight: string | null;
}

interface MatchedRange {
    start: number;
    end: number;
    match: string;
}

@Component
export default class GrammarInfoHighlighter extends Vue {
    @Prop({ type: String, required: true })
    public readonly text!: string;

    public get textElements(): TextElement[] {
        const targetText = this.text.toLowerCase();
        const matchedRanges: MatchedRange[] = [];
        for (const term in termsMap) {
            if (termsMap.hasOwnProperty(term)) {
                const found = targetText.search(term);
                if (found !== -1) {
                    let conflict = false;
                    for (const prevMatch of matchedRanges) {
                        if (found >= prevMatch.start && found <= prevMatch.end) {
                            conflict = true;
                            break;
                        }
                    }
                    if (!conflict) {
                        matchedRanges.push({
                            start: found,
                            end: found + term.length,
                            match: term,
                        });
                    }
                }
            }
        }

        const textElements: TextElement[] = [];
        let position = 0;
        while (position < this.text.length) {
            const currentMatchedRange = matchedRanges.find(range => position >= range.start && position < range.end);
            if (currentMatchedRange) {
                textElements.push({
                    text: this.text.substring(position, currentMatchedRange.end),
                    highlight: this.$t('tooltip.hotTerms.' + termsMap[currentMatchedRange.match]).toString(),
                });
                position = currentMatchedRange.end;
            } else {
                const nextBoundary = matchedRanges
                    .map(t => t.start)
                    .filter(t => t >= position)
                    .reduce((carry, current) => (carry > current ? current : carry), this.text.length);
                textElements.push({
                    text: this.text.substring(position, nextBoundary),
                    highlight: null,
                });
                position = nextBoundary;
            }
        }

        return textElements;
    }
}
