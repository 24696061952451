import { Observable } from 'rxjs';
import GraphQlConnection from '@/repositories/api/GraphQlConnection';
import { map } from 'rxjs/operators';
import Page from '@/repositories/data/Page';

export default function mapConnectionToPage<T>(
    observable: Observable<GraphQlConnection<T> | null>,
): Observable<Page<T> | null> {
    return observable.pipe(
        map(connection =>
            connection
                ? {
                      nodes: connection.edges!.map((edge: any) => edge.node)!,
                      endCursor: connection.pageInfo!.endCursor!,
                      totalNodeCount: connection.pageInfo!.totalNodeCount!,
                      totalPageCount: connection.pageInfo!.totalPageCount!,
                  }
                : null,
        ),
    );
}
