export enum ResourceStatus {
    SUCCESS,
    ERROR,
    LOADING,
}

export default class Resource<T> {
    public static success<T>(data: T | null): Resource<T> {
        return new Resource<T>(ResourceStatus.SUCCESS, data, null);
    }

    public static error<T>(error: any, data?: T | null): Resource<T> {
        return new Resource<T>(ResourceStatus.ERROR, typeof data === 'boolean' || data ? data : null, error);
    }

    public static loading<T>(data: T | null): Resource<T> {
        return new Resource<T>(ResourceStatus.LOADING, data, null);
    }

    public readonly status: ResourceStatus;
    public readonly data: T | null;
    public readonly error: any;

    constructor(status: ResourceStatus, data: T | null, error: any) {
        this.status = status;
        this.data = data;
        this.error = error;
    }

    public map<R>(transform: (data: T | null) => R | null): Resource<R> {
        return new Resource<R>(this.status, transform(this.data), this.error);
    }

    public merge<O, R>(other: Resource<O>, combine: (a: T | null, b: O | null) => R | null): Resource<R> {
        let status: ResourceStatus;
        let error: any = null;
        if (this.isError || other.isError) {
            status = ResourceStatus.ERROR;
        } else if (this.isLoading || other.isLoading) {
            status = ResourceStatus.LOADING;
        } else {
            status = ResourceStatus.SUCCESS;
        }
        if (this.error !== null && other.error !== null) {
            error = [this.error, other.error];
        } else if (this.error !== null) {
            error = this.error;
        } else {
            error = other.error;
        }
        return new Resource<R>(status, combine(this.data, other.data), error);
    }

    public hasData(): this is { data: T } {
        return this.data !== null;
    }

    public get isSuccess(): boolean {
        return this.status === ResourceStatus.SUCCESS;
    }

    public get isLoading(): boolean {
        return this.status === ResourceStatus.LOADING;
    }

    public get isError(): boolean {
        return this.status === ResourceStatus.ERROR;
    }
}
