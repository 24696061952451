
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignGesture from '@/repositories/data/SignGesture';

@Component
export default class SignGestureImage extends Vue {
    @Prop({ type: Object, required: true }) public readonly signGesture!: SignGesture;

    public get src(): string {
        return process.env.VUE_APP_OW_MEDIA_ENDPOINT + '/sign/gesture/' + this.signGesture.id;
    }

    public get alt(): string | null {
        return this.signGesture.labelNl || null;
    }
}
