import Vue from 'vue';
// @ts-ignore
import VuePlyr from 'vue-plyr';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import './class-component-hooks';

// @ts-ignore
window.popStateDetected = false;
window.addEventListener('popstate', () => {
    // @ts-ignore
    window.popStateDetected = true;
});

Vue.config.productionTip = false;

Vue.use(VuePlyr);

new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');
