
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SearchOrderBySelector extends Vue {
    public settingInput: string = 'name';

    @Prop({ type: String, default: '' }) public readonly fieldPrefix!: string;
    @Prop({ type: String, default: 'name' }) public readonly lastValue!: string;

    public get currentSettingLabel(): string {
        return (this.settingInput === 'name'
            ? this.$t('search.ordering.name')
            : this.$t('search.ordering.created-at')
        ).toString();
    }

    public get fieldName(): string {
        return 'ow-search-setting-' + this.fieldPrefix + '-order-by';
    }

    @Watch('settingInput')
    public onSettingChanged(value: string, oldValue: string) {
        if (value !== oldValue) {
            this.$emit('order-by-changed', value);
        }
    }

    @Watch('lastValue', { immediate: true })
    public onLastValueChanged(value: string) {
        this.settingInput = value;
    }
}
