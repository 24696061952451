import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';
import AuthStore from '@/util/auth/AuthStore';
import 'fastestsmallesttextencoderdecoder';

function generateRandomString() {
    let array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
}

function dec2hex(dec: number) {
    return ('0' + dec.toString(16)).substr(-2);
}

class StateData {
    private readonly _id: string;
    private readonly _codeVerifier: string;
    private readonly _expiresAt: number;

    public constructor(data: any) {
        if (data.id) {
            this._id = data.id;
        } else {
            this._id = generateRandomString();
        }
        if (data.code_verifier) {
            this._codeVerifier = data.code_verifier;
        } else {
            this._codeVerifier = generateRandomString();
        }
        if (data.expires_at) {
            this._expiresAt = data.expires_at;
        } else {
            const now = Date.now() / 1000;
            this._expiresAt = now + 3600;
        }
    }

    get id(): string {
        return this._id;
    }

    get codeVerifier(): string {
        return this._codeVerifier;
    }

    public generateCodeChallenge(): Observable<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(this._codeVerifier);
        return fromPromise(window.crypto.subtle.digest('SHA-256', data)).pipe(
            map(buffer => {
                // Convert the ArrayBuffer to string using Uint8 array.
                // btoa takes chars from 0-255 and base64 encodes.
                // Then convert the base64 encoded to base64url encoded.
                // (replace + with -, replace / with _, trim trailing =)
                // @ts-ignore
                return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
                    .replace(/\+/g, '-')
                    .replace(/\//g, '_')
                    .replace(/=+$/, '');
            }),
        );
    }

    public toStorageString(): string {
        return JSON.stringify({
            id: this._id,
            code_verifier: this._codeVerifier,
            expires_at: this._expiresAt,
        });
    }
}

interface AuthParams {
    [key: string]: string;
    response_type: string;
    state: string;
    code_challenge: string;
    code_challenge_method: string;
}

export default class AuthState {
    private readonly store: AuthStore;
    private readonly _data: StateData;

    public constructor(id: string | null = null, store: AuthStore) {
        const data = id ? store.getState(id) : null;
        this.store = store;
        this._data = AuthState.deserialize(data);
        if (!data) {
            this.serialize();
        }
    }

    public generateAuthParams(): Observable<AuthParams> {
        return this._data.generateCodeChallenge().pipe(
            map(
                (codeChallenge): AuthParams => ({
                    response_type: 'code',
                    state: this._data.id,
                    code_challenge: codeChallenge,
                    code_challenge_method: 'S256',
                }),
            ),
        );
    }

    public validateLoginParams(code: string | null, state: string | null): boolean {
        return code !== null && state !== null && state === this._data.id;
    }

    public clearFromStorage(): void {
        this.store.removeState(this._data.id);
    }

    public get codeVerifier(): string {
        return this._data.codeVerifier;
    }

    private serialize(): void {
        this.store.setState(this._data.id, this._data.toStorageString());
    }

    private static deserialize(data: string | null): StateData {
        return new StateData(data ? JSON.parse(data) : {});
    }
}
