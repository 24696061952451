import GraphQlApi from '@/repositories/api/GraphQlApi';
import { Observable } from 'rxjs';
import Resource from '@/repositories/Resource';
import ReferenceData from '@/repositories/data/ReferenceData';
import networkBoundResource from '@/repositories/networkBoundResource';
import unwrapGraphQlResponse from '@/repositories/unwrapGraphQlResponse';

export default class ReferenceDataRepository {
    private readonly api: GraphQlApi;

    constructor(api: GraphQlApi) {
        this.api = api;
    }

    public findReferenceData(moduleId: string | null = null): Observable<Resource<ReferenceData>> {
        const observable = this.api.getReferenceData(moduleId);
        return networkBoundResource(unwrapGraphQlResponse(observable));
    }
}
