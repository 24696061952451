import MediaFile from '@/repositories/data/MediaFile';
import GrammarLevel from '@/repositories/data/GrammarLevel';
import GrammarPart from '@/repositories/data/GrammarPart';
import VariantCategory from '@/repositories/data/VariantCategory';

type ID = string;

export enum GestureFilmType {
    DutchSignLanguage = 'DutchSignLanguage',
    DutchWithSigns = 'DutchWithSigns',
}

export default interface Phrase {
    id?: ID;
    description?: string | null;
    film?: MediaFile | null;
    filmType?: GestureFilmType;
    grammarLevel?: GrammarLevel | null;
    grammarPart?: GrammarPart | null;
    text?: string;
    variantCategory?: VariantCategory;
}
