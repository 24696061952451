
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InformationBulb extends Vue {
    @Prop({ type: String, required: true })
    public readonly text!: string;

    @Prop({
        type: String,
        required: false,
        default: 'top',
        validator: (value: any): boolean =>
            value === 'top' || value === 'bottom' || value === 'left' || value === 'right',
    })
    public readonly direction!: string;

    @Prop({
        type: String,
        required: false,
        default: null,
        validator: (value: any): boolean =>
            value === 'top' || value === 'bottom' || value === 'left' || value === 'right' || value === null,
    })
    public readonly directionMobile!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null,
        validator: (value: any): boolean =>
            value === 'top' || value === 'bottom' || value === 'left' || value === 'right' || value === null,
    })
    public readonly directionTablet!: string | null;

    public get options(): any {
        const directionKey = 'is-tooltip-' + this.direction;
        const options = {
            [directionKey]: true,
        };
        if (this.directionMobile !== null) {
            const directionMobileKey = 'is-tooltip-' + this.directionMobile + '-mobile';
            options[directionMobileKey] = true;
        }
        if (this.directionTablet !== null) {
            const directionTabletKey = 'is-tooltip-' + this.directionTablet + '-tablet-only';
            options[directionTabletKey] = true;
        }
        return options;
    }
}
