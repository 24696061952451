import GraphQlApi from '@/repositories/api/GraphQlApi';
import { Observable } from 'rxjs';
import Resource from '@/repositories/Resource';
import Module from '@/repositories/data/Module';
import Page from '@/repositories/data/Page';
import networkBoundResource from '@/repositories/networkBoundResource';
import mapConnectionToPage from '@/repositories/mapConnectionToPage';
import unwrapGraphQlResponse from '@/repositories/unwrapGraphQlResponse';

export default class ModuleRepository {
    private readonly api: GraphQlApi;

    constructor(api: GraphQlApi) {
        this.api = api;
    }

    public findModules(first: number, after: string | null): Observable<Resource<Page<Module>>> {
        const observable = this.api.getModules(first, after);
        return networkBoundResource(mapConnectionToPage(unwrapGraphQlResponse(observable)));
    }
}
