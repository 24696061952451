import HttpClient from '@/util/http/HttpClient';
import GraphQlApi from '@/repositories/api/GraphQlApi';
import GlobalViewModel from '@/state/GlobalViewModel';
import GlossRepository from '@/repositories/GlossRepository';
import ReferenceDataRepository from '@/repositories/ReferenceDataRepository';
import ModuleRepository from '@/repositories/ModuleRepository';
import AuthStore from '@/util/auth/AuthStore';
import AuthManager from '@/util/auth/AuthManager';
import UserRepository from '@/repositories/UserRepository';
import SignApi from '@/repositories/api/SignApi';
import MapService from '@/state/MapService';

const httpClient = new HttpClient({});
const authStore = new AuthStore();
const graphQlApi = new GraphQlApi(httpClient, process.env.VUE_APP_OW_API_ENDPOINT as string);
const signApi = new SignApi(httpClient, process.env.VUE_APP_OW_API_ENDPOINT as string);
const userRepository = new UserRepository(graphQlApi);
export const glossRepository = new GlossRepository(graphQlApi, signApi);
export const referenceDataRepository = new ReferenceDataRepository(graphQlApi);
export const moduleRepository = new ModuleRepository(graphQlApi);
export const authManager = new AuthManager(
    process.env.VUE_APP_AUTH_ENDPOINT!,
    process.env.VUE_APP_AUTH_CLIENT_ID!,
    httpClient,
    authStore,
);
export const globalViewModel = new GlobalViewModel(authManager, userRepository);
httpClient.registerRawRequestMiddleware(globalViewModel);
httpClient.registerRawResponseMiddleware(globalViewModel);
export const mapService = new MapService(process.env.VUE_APP_GOOGLE_MAPS_API_KEY!, process.env.VUE_APP_GOOGLE_MAPS_MAP_ID!);
