
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class FormattedGlossName extends Vue {
    public static regex = /^([0-9A-Z].*?)( [a-z\W]+|)$/u;

    @Prop({ type: String, required: true }) public readonly value!: string;

    public base: string = '';
    public suffix: string = '';

    @Watch('value', { immediate: true })
    public onValueChange(value: string): void {
        const regexResult = FormattedGlossName.regex.exec(value) || [null, value, ''];
        const [, base, suffix] = regexResult;
        this.base = (base || '').trim();
        this.suffix = (suffix || '').trim();
    }
}
