
import { Component, Inject, Vue } from 'vue-property-decorator';
import SplashScaffold from '../SplashScaffold.vue';
import GlossTextSearchInput from '../GlossTextSearchInput.vue';
import GlobalViewModel from '@/state/GlobalViewModel';
import GlossRepository from '@/repositories/GlossRepository';
import { Subscription } from 'rxjs';
import Resource, { ResourceStatus } from '@/repositories/Resource';
import Page from '@/repositories/data/Page';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import { switchMap } from 'rxjs/operators';
import SeeAlsoSection from '@/components/SeeAlsoSection.vue';
import InformationBulb from '@/components/InformationBulb.vue';

@Component({
    components: {
        SeeAlsoSection,
        SplashScaffold,
        GlossTextSearchInput,
        InformationBulb,
    },
})
export default class SplashScreen extends Vue {
    public newResults: GlossSearchResult[] | null = null;

    private newLoading: boolean = true;
    private searchSubscription = new Subscription();
    private newGlossesSubscription = new Subscription();
    @Inject() private readonly globalViewModel!: GlobalViewModel;
    @Inject() private readonly glossRepository!: GlossRepository;

    public created(): void {
        this.newGlossesSubscription = this.globalViewModel.verifiedUser
            .pipe(switchMap(user => this.glossRepository.findNewGlosses(4, null)))
            .subscribe((glossPageResource: Resource<Page<GlossSearchResult>>) => {
                if (glossPageResource.status === ResourceStatus.SUCCESS) {
                    this.newLoading = false;
                    this.newResults = glossPageResource.data !== null ? glossPageResource.data.nodes : [];
                } else {
                    this.newLoading = glossPageResource.status === ResourceStatus.LOADING;
                }
            });
    }

    public beforeDestroy(): void {
        this.searchSubscription.unsubscribe();
        this.newGlossesSubscription.unsubscribe();
    }

    public onSearchByWord(): void {
        this.$router.push({ name: 'search', query: { q: '' } });
    }

    public onSearchByParameters(): void {
        this.$router.push({ name: 'search', query: { m: 'parameters' } });
    }

    public onSearchByGeolocation(): void {
        this.$router.push({ name: 'search', query: { m: 'geolocation' } });
    }

    public onSearchRecent() {
        this.$router.push({ name: 'search', query: { o: 'recent' } });
    }
}
