
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignOralComponent from '@/repositories/data/SignOralComponent';

@Component
export default class SignOralComponentImage extends Vue {
    @Prop({ type: Object, required: true }) public readonly signOralComponent!: SignOralComponent;

    public get src(): string {
        return process.env.VUE_APP_OW_MEDIA_ENDPOINT + '/sign/oral/' + this.signOralComponent.id;
    }

    public get alt(): string | null {
        return this.signOralComponent.labelNl || null;
    }
}
