
import { Component, Prop, Vue } from 'vue-property-decorator';
import MediaFile from '@/repositories/data/MediaFile';
import Explanation from '@/repositories/data/Explanation';

@Component
export default class ExplanationItem extends Vue {
    @Prop({ type: Object, required: true }) public readonly explanation!: Explanation;
    @Prop({ default: null }) public readonly activeFilm!: MediaFile | null;

    public onLabelClick(event: any): void {
        this.$emit('select', this.explanation, event);
    }

    public get label(): string {
        return this.explanation.film ? 'NGT' : '';
    }

    public get className(): string | object {
        return {
            'has-video': this.explanation.film !== null,
            'is-ngt': this.explanation.film !== null,
            playing: this.activeFilm && this.explanation.film && this.explanation.film.id === this.activeFilm.id,
        };
    }
}
