import GraphQlApi from '@/repositories/api/GraphQlApi';
import GlossFilter from '@/repositories/data/GlossFilter';
import {Observable, of} from 'rxjs';
import Resource from '@/repositories/Resource';
import Page from '@/repositories/data/Page';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import Gloss from '@/repositories/data/Gloss';
import GlossOrdering, {OrderMode} from '@/repositories/data/GlossOrdering';
import networkBoundResource from '@/repositories/networkBoundResource';
import mapConnectionToPage from '@/repositories/mapConnectionToPage';
import unwrapGraphQlResponse from '@/repositories/unwrapGraphQlResponse';
import SignApi from '@/repositories/api/SignApi';
import SignApiPage from '@/repositories/data/SignApiPage';
import {Pageable, SortDirection} from '@/repositories/data/Pageable';
import GeolocationFilter from '@/repositories/data/GeolocationFilter';

export default class GlossRepository {
    private readonly api: GraphQlApi;
    private readonly signApi: SignApi;

    constructor(api: GraphQlApi, signApi: SignApi) {
        this.api = api;
        this.signApi = signApi;
    }

    public findGlossesByOptions(options: {
        keyword?: string | null;
        anywhere?: boolean;
        shapeLeft?: string | null;
        shapeRight?: string | null;
        location?: string | null;
        gestureLeft?: string | null;
        gestureRight?: string | null;
        oralComponent?: string | null;
        minLatitude?: number;
        maxLatitude?: number;
        minLongitude?: number;
        maxLongitude?: number;
        mapLayers?: string[];
        themeId?: string | null;
        variantCategoryId?: string | null;
        moduleId: string | null;
        pageable: Pageable;
    }): Observable<Resource<SignApiPage<GlossSearchResult>>> {
        if ('keyword' in options) {
            return this.findGlossesByKeyword(options);
        }
        if (
            'minLatitude' in options &&
            'maxLatitude' in options &&
            'minLongitude' in options &&
            'maxLongitude' in options &&
            'mapLayers' in options
        ) {
            return this.findGlossesByGeolocation(options);
        }
        return this.findGlossesByParameters(options);
    }

    public findGlossesByKeyword({
        keyword,
        anywhere,
        themeId,
        variantCategoryId,
        moduleId,
        pageable,
    }: {
        keyword?: string | null;
        anywhere?: boolean;
        themeId?: string | null;
        variantCategoryId?: string | null;
        moduleId: string | null;
        pageable: Pageable;
    }): Observable<Resource<SignApiPage<GlossSearchResult>>> {
        const observable = this.signApi.findGlossesByKeyword(
            keyword || null,
            anywhere || false,
            themeId || null,
            variantCategoryId || null,
            moduleId,
            pageable,
        );
        return networkBoundResource(observable);
    }

    public findGlossesByGeolocation({
        minLatitude,
        maxLatitude,
        minLongitude,
        maxLongitude,
        mapLayers,
        themeId,
        variantCategoryId,
        moduleId,
    }: Partial<GeolocationFilter> & {
        themeId?: string | null;
        variantCategoryId?: string | null;
        moduleId: string | null;
    }): Observable<Resource<SignApiPage<GlossSearchResult>>> {
        if (minLatitude === maxLatitude || minLongitude === maxLongitude) {
            return of(
                Resource.success({
                    content: [],
                    number: 0,
                    numberOfElements: 0,
                    size: 0,
                    totalElements: 0,
                    totalPages: 0,
                }),
            );
        }
        const observable = this.signApi.findGlossesByGeolocation(
            minLatitude || 0,
            maxLatitude || 0,
            minLongitude || 0,
            maxLongitude || 0,
            mapLayers || [],
            themeId || null,
            variantCategoryId || null,
            moduleId,
            {
                page: 0,
                size: 1000,
                sort: [{ property: 'mapPriority', direction: SortDirection.ASC }],
            },
        );
        return networkBoundResource(observable);
    }

    public findGlossesByParameters({
        shapeLeft,
        shapeRight,
        location,
        gestureLeft,
        gestureRight,
        oralComponent,
        themeId,
        variantCategoryId,
        moduleId,
        pageable,
    }: {
        shapeLeft?: string | null;
        shapeRight?: string | null;
        location?: string | null;
        gestureLeft?: string | null;
        gestureRight?: string | null;
        oralComponent?: string | null;
        themeId?: string | null;
        variantCategoryId?: string | null;
        moduleId: string | null;
        pageable: Pageable;
    }): Observable<Resource<SignApiPage<GlossSearchResult>>> {
        const observable = this.signApi.findGlossesByParameter(
            shapeLeft || null,
            shapeRight || null,
            location || null,
            gestureLeft || null,
            gestureRight || null,
            oralComponent || null,
            themeId || null,
            variantCategoryId || null,
            moduleId,
            pageable,
        );
        return networkBoundResource(observable);
    }

    public findGlossesByFavourite(
        moduleId: string | null,
        pageable: Pageable,
    ): Observable<Resource<SignApiPage<GlossSearchResult>>> {
        const observable = this.signApi.findGlossesByFavourite(moduleId, pageable);
        return networkBoundResource(observable);
    }

    public findGlosses(
        first: number,
        after: string | null,
        where: GlossFilter,
        orderBy: GlossOrdering | null,
        moduleId: string | null = null,
    ): Observable<Resource<Page<GlossSearchResult>>> {
        const observable = this.api.getGlossesPage(first, after, where, orderBy, moduleId);
        return networkBoundResource(mapConnectionToPage(unwrapGraphQlResponse(observable)));
    }

    public findGloss(where: GlossFilter, moduleId: string | null = null): Observable<Resource<Gloss>> {
        const observable = this.api.getGloss(where, moduleId);
        return networkBoundResource(unwrapGraphQlResponse(observable));
    }

    public findGlossByVariant(id: string, moduleId: string | null = null): Observable<Resource<Gloss>> {
        return this.findGloss(
            {
                variants_some: { id },
            },
            moduleId,
        );
    }

    public findGlossById(id: string, moduleId: string | null = null): Observable<Resource<Gloss>> {
        return this.findGloss({ id }, moduleId);
    }

    public findNewGlosses(first: number, after: string | null): Observable<Resource<Page<GlossSearchResult>>> {
        return this.findGlosses(
            first,
            after,
            {
                id_ne: '0', // ugly hack; otherwise no results are returned
            },
            {
                createdAt: OrderMode.DESC,
            },
        );
    }

    public setAsFavourite(id: string, favourite: boolean): Observable<Resource<boolean>> {
        const observable = this.api.setGlossFavoured(id, favourite);
        return networkBoundResource(unwrapGraphQlResponse(observable));
    }
}
