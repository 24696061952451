
import { Component, Provide, Vue } from 'vue-property-decorator';
import {
    authManager,
    globalViewModel,
    glossRepository,
    mapService,
    moduleRepository,
    referenceDataRepository,
} from '@/container';

@Component
export default class App extends Vue {
    @Provide()
    public globalViewModel = globalViewModel;

    @Provide()
    public glossRepository = glossRepository;

    @Provide()
    public referenceDataRepository = referenceDataRepository;

    @Provide()
    public moduleRepository = moduleRepository;

    @Provide()
    public authManager = authManager;

    @Provide()
    public mapService = mapService;
}
