
import { Component, Inject, Vue } from 'vue-property-decorator';
import GlobalViewModel from '../../state/GlobalViewModel';
import ModuleRepository from '../../repositories/ModuleRepository';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import Module from '../../repositories/data/Module';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ResourceStatus } from '@/repositories/Resource';
import GeneralScaffold from '@/components/GeneralScaffold.vue';
import Pagination from '@/components/Pagination.vue';

@Component({
    components: {
        GeneralScaffold,
        Pagination,
    },
})
export default class ModulesScreen extends Vue {
    public searchResults: Module[] = [];
    public searchLoading: boolean = false;
    public totalPageCount = 0;

    private searchResultsTotal: number = 0;
    private paginationSize = 16;
    private currentPageSubject = new BehaviorSubject<number>(1);
    private modulesSubscription = new Subscription();
    @Inject() private readonly globalViewModel!: GlobalViewModel;
    @Inject() private readonly moduleRepository!: ModuleRepository;

    public created(): void {
        this.modulesSubscription = combineLatest([
            this.globalViewModel.verifiedUser,
            this.currentPageSubject.pipe(distinctUntilChanged()),
        ])
            .pipe(
                switchMap(([user, currentPage]) =>
                    this.moduleRepository.findModules(
                        this.paginationSize,
                        '' + ((currentPage - 1) * this.paginationSize - 1),
                    ),
                ),
            )
            .subscribe(modulesResource => {
                if (modulesResource.status === ResourceStatus.SUCCESS) {
                    if (modulesResource.data !== null) {
                        this.searchResults = modulesResource.data.nodes;
                        this.searchResultsTotal = modulesResource.data.totalNodeCount;
                        this.totalPageCount = modulesResource.data.totalPageCount;
                    } else {
                        this.searchResults = [];
                        this.searchResultsTotal = 0;
                    }
                }
                this.searchLoading = modulesResource.status === ResourceStatus.LOADING;
            });
    }

    public beforeDestroy(): void {
        this.modulesSubscription.unsubscribe();
    }

    public onPageSelected(page: number) {
        this.currentPageSubject.next(page);
    }

    public onShowModule(module: Module): void {
        this.$router.push({ name: 'search', query: { l: module.id } });
    }

    public getModuleThumbnailUrl(module: Module): string {
        if (module.banner && module.banner.id) {
            return `${process.env.VUE_APP_OW_MEDIA_ENDPOINT}/media/img/${module.banner.id}`;
        }
        return 'https://placehold.jp/1280x720.png?text=' + module.name;
    }
}
