
import { Component, Inject, Vue } from 'vue-property-decorator';
import SplashScaffold from '@/components/SplashScaffold.vue';
import GlobalViewModel from '@/state/GlobalViewModel';
import { Subscription } from 'rxjs';
import AuthManager from '@/util/auth/AuthManager';
import { Route } from 'vue-router';

function popCachedRoute(): Route | null {
    const data = localStorage.getItem('ow_cached_route');
    if (data) {
        const route: any = JSON.parse(data);
        localStorage.removeItem('ow_cached_route');
        return route;
    }
    return null;
}

@Component({
    components: {
        SplashScaffold,
    },
})
export default class LoginScreen extends Vue {
    public loading: boolean = true;
    public error = false;

    private authSubscription = new Subscription();
    @Inject() private readonly authManager!: AuthManager;
    @Inject() private readonly globalViewModel!: GlobalViewModel;

    public created(): void {
        if (this.$route.query.hasOwnProperty('code') && this.$route.query.hasOwnProperty('state')) {
            this.authSubscription = this.authManager
                .validateLogin(this.$route.query.code as string, this.$route.query.state as string)
                .subscribe(tokenResource => {
                    this.loading = tokenResource.isLoading;
                    if (tokenResource.isSuccess) {
                        const cachedRoute = popCachedRoute();
                        if (cachedRoute) {
                            this.$router.push({ ...cachedRoute, name: cachedRoute.name! });
                        } else {
                            this.$router.push({ name: 'home' });
                        }
                    } else if (tokenResource.isError) {
                        this.error = true;
                        this.globalViewModel.reportError();
                    }
                });
        } else {
            this.$router.push({ name: 'home' });
        }
    }

    public beforeDestroy(): void {
        this.authSubscription.unsubscribe();
    }
}
