
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SignOralComponent from '@/repositories/data/SignOralComponent';
import SignGesture from '@/repositories/data/SignGesture';
import SignShape from '@/repositories/data/SignShape';
import SignShapeImage from '@/components/SignShapeImage.vue';
import SignGestureImage from '@/components/SignGestureImage.vue';
import SignOralComponentImage from '@/components/SignOralComponentImage.vue';
import SignLocation from '@/repositories/data/SignLocation';
import SignLocationImage from '@/components/SignLocationImage.vue';
import InformationBulb from '@/components/InformationBulb.vue';

type SignProperty = SignShape | SignGesture | SignLocation | SignOralComponent;
const typeValidator = (value: string): boolean =>
    ['shapeLeft', 'shapeRight', 'gestureLeft', 'gestureRight', 'location', 'oralComponent'].includes(value);

@Component({
    components: {
        SignLocationImage,
        SignOralComponentImage,
        SignShapeImage,
        SignGestureImage,
        InformationBulb,
    },
})
export default class SignPropertySelector extends Vue {
    public selectionInput: string = '';

    @Prop({ type: Boolean, default: true }) public readonly isLoading!: boolean;
    @Prop({ type: Array, default: () => [] }) public readonly availableProperties!: SignProperty[];
    @Prop({ type: String, required: true, validator: typeValidator }) public readonly type!: string;
    @Prop({ type: String, default: '' }) public readonly lastValue!: string;
    @Prop({ type: String, default: '' }) public readonly fieldPrefix!: string;
    @Prop({ type: Boolean, default: true }) public readonly visible!: boolean;
    public openUpward = false;
    public search = '';
    public forceActive = false;

    public get filteredProperties(): SignProperty[] {
        return this.search.length === 0
            ? this.availableProperties
            : this.availableProperties.filter(t => t.labelNl!.toLowerCase().startsWith(this.search.toLowerCase()));
    }

    public mounted(): void {
        if (this.type !== 'location') {
            window.addEventListener('scroll', this.positionHandler);
            window.addEventListener('resize', this.positionHandler);
            this.onPositionUpdated();
        }
    }

    public beforeDestroy(): void {
        if (this.type !== 'location') {
            window.removeEventListener('scroll', this.positionHandler);
            window.removeEventListener('resize', this.positionHandler);
        }
    }

    public get currentSignProperty(): SignProperty | undefined {
        return this.availableProperties.find(signProperty => signProperty.id === this.selectionInput);
    }

    // noinspection JSMethodCanBeStatic
    public get defaultLocation(): SignLocation {
        return {
            id: '0',
            labelNl: this.$t('search.none').toString(),
        };
    }

    public get fieldName(): string {
        return 'ow-search-setting-' + this.fieldPrefix + '-' + this.type;
    }

    public onPositionUpdated(): void {
        const dropdown = this.$refs.dropdown as any;
        if (dropdown) {
            const scrollOffset = dropdown.getBoundingClientRect().top;
            const totalHeight = window.innerHeight;
            const upward = scrollOffset > totalHeight / 2;
            if (this.openUpward !== upward) {
                this.openUpward = upward;
            }
        }
    }

    public onSearchFocused(): void {
        this.forceActive = true;
    }

    public onSearchBlurred(): void {
        this.forceActive = false;
    }

    @Watch('visible')
    public onVisibilityChanged(): void {
        this.onPositionUpdated();
    }

    @Watch('selectionInput')
    public onSelectionInputChanged(input: string, oldValue: string) {
        if (input !== oldValue) {
            this.$emit('sign-property-changed', input);
        }
    }

    @Watch('lastValue', { immediate: true })
    public onLastValueChanged(value: string) {
        this.selectionInput = value;
    }

    public onLocationSelected(input: string) {
        this.selectionInput = input;
    }

    private positionHandler = (): void => {
        this.onPositionUpdated();
    };
}
