
import { Component, Prop, Vue } from 'vue-property-decorator';
import VariantCategory from '@/repositories/data/VariantCategory';

@Component
export default class GlossVariantCategorySelector extends Vue {
    @Prop({ type: Object, required: false }) public readonly selected!: VariantCategory;
    @Prop({ type: Array, required: true }) public readonly items!: VariantCategory[];

    public onSelect(category: VariantCategory): void {
        this.$emit('select', category);
    }

    get notSelectItems(): VariantCategory[] {
        return this.items.filter(x => this.selected && x.id !== this.selected.id);
    }
}
