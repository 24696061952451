import Vue from 'vue';
import VueRouter, { NavigationGuard, Route } from 'vue-router';
import SplashScreen from '@/components/screens/SplashScreen.vue';
import LoginScreen from '@/components/screens/LoginScreen.vue';
import SearchScreen from '@/components/screens/SearchScreen.vue';
import FavouritesScreen from '@/components/screens/FavouritesScreen.vue';
import ModulesScreen from '@/components/screens/ModulesScreen.vue';
import GlossDetailsScreen from '@/components/screens/GlossDetailsScreen.vue';
import { Component } from 'vue-property-decorator';
import NotFoundScreen from '@/components/screens/NotFoundScreen.vue';
import { authManager } from '@/container';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: SplashScreen,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginScreen,
    },
    {
        path: '/search',
        name: 'search',
        component: SearchScreen,
        meta: {
            asyncScroll: true,
        },
    },
    {
        path: '/favourites',
        name: 'favourites',
        component: FavouritesScreen,
        meta: {
            asyncScroll: true,
        },
    },
    {
        path: '/lists',
        name: 'lists',
        component: ModulesScreen,
    },
    {
        path: '/gloss/:id/:category_id?',
        name: 'gloss-details',
        component: GlossDetailsScreen,
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFoundScreen,
    },
];

let verifiedSessionAlive = false;

function cacheRoute(route: Route): void {
    const { name, params, query } = route;
    const cachedRoute = { name, params, query };
    localStorage.setItem('ow_cached_route', JSON.stringify(cachedRoute));
}

const authGuard: NavigationGuard = (to, from, next) => {
    if ((!authManager.token || authManager.expired) && !verifiedSessionAlive && to.name !== 'login') {
        authManager.isSessionAlive().subscribe(resource => {
            if (!resource.isLoading) {
                verifiedSessionAlive = true;
                if (resource.isSuccess && resource.data) {
                    cacheRoute(to);
                    authManager.startRedirectLogin();
                } else {
                    next();
                }
            }
        });
    } else {
        next();
    }
};

const scrollBehavior = function(to: Route, from: Route, savedPosition: any) {
    if (savedPosition) {
        if (to.matched.some(m => m.meta.asyncScroll)) {
            return new Promise(resolve => {
                // @ts-ignore
                this.app.$root.$once('readyToScroll', () => {
                    setTimeout(() => {
                        resolve(savedPosition);
                    }, 200);
                });
            });
        }
        return savedPosition;
    } else {
        return { x: 0, y: 0 };
    }
};

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior,
});

router.beforeEach(authGuard);

export default router;
