
import { Component, Inject, Vue } from 'vue-property-decorator';
import GlobalViewModel from '../state/GlobalViewModel';
import { Subscription } from 'rxjs';

@Component({})
export default class ErrorReportsFrame extends Vue {
    public showError = false;
    public showGuestWarning = false;

    @Inject() private readonly globalViewModel!: GlobalViewModel;
    private showErrorSubscription = new Subscription();
    private showGuestWarningSubscription = new Subscription();

    public created(): void {
        this.showErrorSubscription = this.globalViewModel.isErrorActive.subscribe(errorActive => {
            this.showError = errorActive;
        });
        this.showGuestWarningSubscription = this.globalViewModel.isGuestWarningActive.subscribe(guestWarningActive => {
            this.showGuestWarning = guestWarningActive;
        });
    }

    public beforeDestroy(): void {
        this.showErrorSubscription.unsubscribe();
        this.showGuestWarningSubscription.unsubscribe();
    }

    public dismissError(): void {
        this.globalViewModel.dismissError();
    }

    public dismissGuestWarning(): void {
        this.globalViewModel.dismissGuestWarning();
    }
}
