
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import GeneralScaffold from '../GeneralScaffold.vue';
import GlobalViewModel from '@/state/GlobalViewModel';
import GlossRepository from '@/repositories/GlossRepository';
import { BehaviorSubject, combineLatest, merge, Subscription } from 'rxjs';
import Resource, { ResourceStatus } from '@/repositories/Resource';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import Theme from '@/repositories/data/Theme';
import VariantCategory from '@/repositories/data/VariantCategory';
import SearchThemeSelector from '@/components/SearchThemeSelector.vue';
import SearchVariantCategorySelector from '@/components/SearchVariantCategorySelector.vue';
import SearchSignParametersSelector from '@/components/SearchSignParametersSelector.vue';
import SearchOrderBySelector from '@/components/SearchOrderBySelector.vue';
import SignShape from '@/repositories/data/SignShape';
import SignGesture from '@/repositories/data/SignGesture';
import SignOralComponent from '@/repositories/data/SignOralComponent';
import SignLocation from '@/repositories/data/SignLocation';
import SignPropertySelection from '@/repositories/data/SignPropertySelection';
import Pagination from '@/components/Pagination.vue';
import ReferenceDataRepository from '@/repositories/ReferenceDataRepository';
import MediaFileThumbnail from '@/components/MediaFileThumbnail.vue';
import { SortDirection } from '@/repositories/data/Pageable';
import SignApiPage from '@/repositories/data/SignApiPage';
import GeolocationFilter from '@/repositories/data/GeolocationFilter';
import GeolocationFilterSelector from '@/components/GeolocationFilterSelector.vue';

interface SignPropertyFilter {
    type: string;
    id: string;
    label: string;
}

function signPropertyComparer(a: SignPropertySelection, b: SignPropertySelection): boolean {
    return (
        a.shapeLeft === b.shapeLeft &&
        a.shapeRight === b.shapeRight &&
        a.gestureLeft === b.gestureLeft &&
        a.gestureRight === b.gestureRight &&
        a.location === b.location &&
        a.oralComponent === b.oralComponent
    );
}

function geolocationFilterComparer(a: GeolocationFilter, b: GeolocationFilter): boolean {
    return (
        a.minLatitude === b.minLatitude &&
        a.maxLatitude === b.maxLatitude &&
        a.minLongitude === b.minLongitude &&
        a.maxLongitude === b.maxLongitude &&
        a.mapLayers.length === b.mapLayers.length &&
        a.mapLayers.every((value, index) => value === b.mapLayers[index])
    );
}

@Component({
    components: {
        GeolocationFilterSelector,
        SearchSignParametersSelector,
        GeneralScaffold,
        SearchThemeSelector,
        SearchVariantCategorySelector,
        SearchOrderBySelector,
        Pagination,
        MediaFileThumbnail,
    },
})
export default class SearchScreen extends Vue {
    public searchMode = 'words';
    public referenceDataLoading = true;
    public availableThemes: Theme[] = [];
    public availableVariantCategories: VariantCategory[] = [];
    public signShapes: SignShape[] = [];
    public signGestures: SignGesture[] = [];
    public signLocations: SignLocation[] = [];
    public signOralComponents: SignOralComponent[] = [];
    public themeIdSubject = new BehaviorSubject<string | null>(null);
    public variantCategoryIdSubject = new BehaviorSubject<string>('0');
    public signPropertySelectionSubject = new BehaviorSubject<SignPropertySelection>({
        shapeLeft: '',
        shapeRight: '',
        gestureLeft: '',
        gestureRight: '',
        location: '',
        oralComponent: '',
    });
    public geolocationFilterSubject = new BehaviorSubject<GeolocationFilter>({
        minLatitude: 0,
        maxLatitude: 0,
        minLongitude: 0,
        maxLongitude: 0,
        mapLayers: ['none'],
    });
    public currentPageSubject = new BehaviorSubject<number>(1);
    public orderingSubject = new BehaviorSubject<string>('name');
    public moduleSubject = new BehaviorSubject<string | null>(null);
    public searchResults: GlossSearchResult[] = [];
    public searchLoading: boolean = false;
    public searchTextQueryBasis: string = '';
    public searchResultsTotal: number = 0;
    public moduleName: string | null = null;
    public totalPageCount = 0;
    public filterMenuShown = false;
    public activeSignPropertyFilters: SignPropertyFilter[] = [];
    public latestQuickSearchResult: GlossSearchResult | null = null;

    private paginationSize = 25;
    private modeSubject = new BehaviorSubject<string>(this.searchMode);
    private searchSubject = new BehaviorSubject<string>('');
    private searchKeywordsSettingSubject = new BehaviorSubject<string>('starts_with');
    private searchSubscription = new Subscription();
    private referenceDataSubscription = new Subscription();
    private signPropertyFiltersSubscription = new Subscription();
    private routeQueryInfluencersSubscription = new Subscription();
    @Inject() private readonly globalViewModel!: GlobalViewModel;
    @Inject() private readonly glossRepository!: GlossRepository;
    @Inject() private readonly referenceDataRepository!: ReferenceDataRepository;

    public get showingParameterSelection(): boolean {
        return this.searchMode === 'parameters';
    }

    public get showingGeolocation(): boolean {
        return this.searchMode === 'geolocation';
    }

    public created(): void {
        this.updateFromRouteQuery(true);
        this.searchSubscription = combineLatest([
            this.globalViewModel.verifiedUser,
            this.currentPageSubject.pipe(distinctUntilChanged()),
            this.orderingSubject.pipe(distinctUntilChanged()),
            this.moduleSubject.pipe(distinctUntilChanged()),
            combineLatest([
                this.modeSubject,
                combineLatest([
                    this.searchSubject.pipe(distinctUntilChanged(), debounceTime(300)),
                    this.searchKeywordsSettingSubject,
                ]).pipe(
                    map(([search, searchKeywordSetting]) => ({
                        keyword: search,
                        anywhere: searchKeywordSetting !== 'starts_with',
                    })),
                ),
                this.signPropertySelectionSubject.pipe(distinctUntilChanged(signPropertyComparer), debounceTime(300)),
                this.geolocationFilterSubject.pipe(distinctUntilChanged(geolocationFilterComparer), debounceTime(300)),
                this.themeIdSubject.pipe(distinctUntilChanged()),
                this.variantCategoryIdSubject.pipe(distinctUntilChanged()),
            ]).pipe(
                map(([mode, keywordsFilter, signPropertyFilter, geolocationFilter, themeId, variantCategoryId]) => ({
                    ...(mode === 'words' && keywordsFilter),
                    ...(mode === 'parameters' && signPropertyFilter),
                    ...(mode === 'geolocation' && geolocationFilter),
                    themeId,
                    variantCategoryId,
                })),
            ),
        ])
            .pipe(
                switchMap(([user, currentPage, orderBy, moduleId, glossFilter]) =>
                    this.glossRepository.findGlossesByOptions({
                        ...glossFilter,
                        moduleId,
                        pageable: {
                            page: currentPage - 1,
                            size: this.paginationSize,
                            sort:
                                orderBy === 'name'
                                    ? [{ property: 'name', direction: SortDirection.ASC }]
                                    : [{ property: 'createdAt', direction: SortDirection.DESC }],
                        },
                    }),
                ),
            )
            .subscribe((glossPageResource: Resource<SignApiPage<GlossSearchResult>>) => {
                if (glossPageResource.status === ResourceStatus.SUCCESS) {
                    if (glossPageResource.data !== null) {
                        this.searchResults = glossPageResource.data.content;
                        this.searchResultsTotal = glossPageResource.data.totalElements;
                        this.totalPageCount = glossPageResource.data.totalPages;
                        this.$root.$emit('readyToScroll');
                    } else {
                        this.searchResults = [];
                        this.searchResultsTotal = 0;
                    }
                }
                this.searchTextQueryBasis = this.searchSubject.getValue();
                this.searchLoading = glossPageResource.status === ResourceStatus.LOADING;
            });

        const referenceDataObservable = combineLatest([
            this.globalViewModel.verifiedUser,
            this.moduleSubject.pipe(distinctUntilChanged()),
        ]).pipe(switchMap(([user, moduleId]) => this.referenceDataRepository.findReferenceData(moduleId)));

        this.referenceDataSubscription = referenceDataObservable.subscribe(referenceData => {
            this.referenceDataLoading = referenceData.isLoading;
            if (referenceData.data !== null && referenceData.data.themes) {
                this.availableThemes = referenceData.data.themes;
            } else {
                this.availableThemes = [];
            }
            if (referenceData.data !== null && referenceData.data.variantCategories) {
                this.availableVariantCategories = referenceData.data.variantCategories;
            } else {
                this.availableVariantCategories = [];
            }
            if (referenceData.data !== null && referenceData.data.signShapes) {
                this.signShapes = referenceData.data.signShapes;
            } else {
                this.signShapes = [];
            }
            if (referenceData.data !== null && referenceData.data.signGestures) {
                this.signGestures = referenceData.data.signGestures;
            } else {
                this.signGestures = [];
            }
            if (referenceData.data !== null && referenceData.data.signLocations) {
                this.signLocations = referenceData.data.signLocations;
            } else {
                this.signLocations = [];
            }
            if (referenceData.data !== null && referenceData.data.signOralComponents) {
                this.signOralComponents = referenceData.data.signOralComponents;
            } else {
                this.signOralComponents = [];
            }
            if (referenceData.data !== null && referenceData.data.module && referenceData.data.module.name) {
                this.moduleName = referenceData.data.module.name;
            } else {
                this.moduleName = null;
            }
        });

        this.routeQueryInfluencersSubscription = merge(
            this.signPropertySelectionSubject.pipe(
                map(selection => {
                    const queryUpdate: any = {
                        sl: selection.shapeLeft.length > 0 ? selection.shapeLeft : null,
                        sr: selection.shapeRight.length > 0 ? selection.shapeRight : null,
                        gl: selection.gestureLeft.length > 0 ? selection.gestureLeft : null,
                        gr: selection.gestureRight.length > 0 ? selection.gestureRight : null,
                        lc: selection.location.length > 0 ? selection.location : null,
                        oc: selection.oralComponent.length > 0 ? selection.oralComponent : null,
                    };
                    return queryUpdate;
                }),
            ),
            this.themeIdSubject.pipe(
                map(id => {
                    const queryUpdate: any = {
                        t: id,
                    };
                    return queryUpdate;
                }),
            ),
            this.variantCategoryIdSubject.pipe(
                map(id => {
                    const queryUpdate: any = {
                        v: id !== '0' ? id : null,
                    };
                    return queryUpdate;
                }),
            ),
        ).subscribe(queryUpdate => {
            this.mergeRouteQuery(queryUpdate);
        });

        this.signPropertyFiltersSubscription = combineLatest([
            referenceDataObservable,
            this.signPropertySelectionSubject,
        ]).subscribe(([referenceData, selection]) => {
            const filters: SignPropertyFilter[] = [];
            if (selection.shapeLeft.length > 0) {
                filters.push({
                    type: 'shapeLeft',
                    id: selection.shapeLeft,
                    label:
                        referenceData.data !== null && referenceData.data.signShapes
                            ? referenceData.data.signShapes.find(entry => entry.id === selection.shapeLeft)!.labelNl!
                            : '',
                });
            }
            if (selection.shapeRight.length > 0) {
                filters.push({
                    type: 'shapeRight',
                    id: selection.shapeRight,
                    label:
                        referenceData.data !== null && referenceData.data.signShapes
                            ? referenceData.data.signShapes.find(entry => entry.id === selection.shapeRight)!.labelNl!
                            : '',
                });
            }
            if (selection.gestureLeft.length > 0) {
                filters.push({
                    type: 'gestureLeft',
                    id: selection.gestureLeft,
                    label:
                        referenceData.data !== null && referenceData.data.signGestures
                            ? referenceData.data.signGestures.find(entry => entry.id === selection.gestureLeft)!
                                  .labelNl!
                            : '',
                });
            }
            if (selection.gestureRight.length > 0) {
                filters.push({
                    type: 'gestureRight',
                    id: selection.gestureRight,
                    label:
                        referenceData.data !== null && referenceData.data.signGestures
                            ? referenceData.data.signGestures.find(entry => entry.id === selection.gestureRight)!
                                  .labelNl!
                            : '',
                });
            }
            if (selection.location.length > 0) {
                filters.push({
                    type: 'location',
                    id: selection.location,
                    label:
                        referenceData.data !== null && referenceData.data.signLocations
                            ? referenceData.data.signLocations.find(entry => entry.id === selection.location)!.labelNl!
                            : '',
                });
            }
            if (selection.oralComponent.length > 0) {
                filters.push({
                    type: 'oralComponent',
                    id: selection.oralComponent,
                    label:
                        referenceData.data !== null && referenceData.data.signOralComponents
                            ? referenceData.data.signOralComponents.find(entry => entry.id === selection.oralComponent)!
                                  .labelNl!
                            : '',
                });
            }
            this.activeSignPropertyFilters = filters;
        });
    }

    public mounted(): void {
        window.addEventListener('resize', this.resizeHandler);
    }

    public beforeDestroy(): void {
        window.removeEventListener('resize', this.resizeHandler);
        this.signPropertyFiltersSubscription.unsubscribe();
        this.routeQueryInfluencersSubscription.unsubscribe();
        this.referenceDataSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
    }

    public onSearchTextQuery(query: string): void {
        this.searchSubject.next(query);
        this.mergeRouteQuery({ q: query });
    }

    public onSearchKeywordSettingChanged(setting: string): void {
        this.searchKeywordsSettingSubject.next(setting);
    }

    public onLatestQuickSearchResultChanged(result: GlossSearchResult | null): void {
        this.latestQuickSearchResult = result;
    }

    public onShowGloss(gloss: GlossSearchResult): void {
        const query: any = {};
        if (this.modeSubject.value === 'words' && this.searchSubject.value.length > 0) {
            query.q = this.searchSubject.value;
        }
        if (this.moduleSubject.value !== null) {
            query.l = this.moduleSubject.value;
        }
        this.$router.push({
            name: 'gloss-details',
            params: {
                id: gloss.id,
                category_id: this.variantCategoryIdSubject.value,
            },
            query,
        });
    }

    public onChangeThemeSetting(themeId: string | null) {
        this.themeIdSubject.next(themeId);
    }

    public onChangeVariantCategorySetting(variantCategoryId: string) {
        this.variantCategoryIdSubject.next(variantCategoryId);
    }

    public onSelectWordTab(): void {
        this.searchMode = 'words';
    }

    public onSelectParametersTab(): void {
        this.searchMode = 'parameters';
    }

    public onSelectGeolocationTab(): void {
        this.searchMode = 'geolocation';
    }

    public onPageSelected(page: number) {
        this.currentPageSubject.next(page);
        this.mergeRouteQuery({ p: page.toString(10) });
    }

    public onOrderByChanged(orderBy: string) {
        this.orderingSubject.next(orderBy);
        this.mergeRouteQuery({ o: orderBy === 'createdAt' ? 'recent' : null });
    }

    @Watch('searchMode')
    public onSearchModeChanged(value: string): void {
        this.modeSubject.next(value);
        const routeValue = value === 'words' ? null : value;
        this.mergeRouteQuery({ m: routeValue });
    }

    @Watch('filterMenuShown')
    public onFilterMenuShownChanged(value: boolean): void {
        this.globalViewModel.isMobileFullscreenModalOpen = value;
    }

    public onScreenResize(): void {
        if (window.innerWidth > 768) {
            this.filterMenuShown = false;
        }
    }

    public onSignPropertySelectionChanged(signPropertySelection: SignPropertySelection) {
        this.signPropertySelectionSubject.next(signPropertySelection);
    }

    public onGeolocationFilterChanged(filter: GeolocationFilter) {
        this.geolocationFilterSubject.next(filter);
    }

    public onClearFilter(type: string): void {
        const selection: SignPropertySelection = {
            ...this.signPropertySelectionSubject.value,
            [type]: '',
        };
        this.signPropertySelectionSubject.next(selection);
    }

    public onClearFilters(): void {
        this.signPropertySelectionSubject.next({
            shapeLeft: '',
            shapeRight: '',
            gestureLeft: '',
            gestureRight: '',
            location: '',
            oralComponent: '',
        });
    }

    @Watch('$route')
    public onRouteChanged() {
        this.updateFromRouteQuery(false);
    }

    private resizeHandler = (): void => {
        this.onScreenResize();
    };

    private updateFromRouteQuery(initial: boolean): void {
        if (initial && this.$route.query.hasOwnProperty('q')) {
            this.onSearchTextQuery(this.$route.query.q as string);
        }
        if (this.$route.query.hasOwnProperty('m')) {
            const mode = this.$route.query.m;
            if (mode === 'parameters' || mode === 'geolocation') {
                this.searchMode = mode;
            } else {
                this.searchMode = 'words';
            }
        }
        if (this.$route.query.hasOwnProperty('o')) {
            this.orderingSubject.next(this.$route.query.o === 'recent' ? 'createdAt' : 'name');
        }
        if (this.$route.query.hasOwnProperty('l')) {
            this.moduleSubject.next(typeof this.$route.query.l === 'string' ? this.$route.query.l : null);
        }
        if (this.$route.query.hasOwnProperty('t')) {
            this.themeIdSubject.next(typeof this.$route.query.t === 'string' ? this.$route.query.t : null);
        }
        if (this.$route.query.hasOwnProperty('v')) {
            this.variantCategoryIdSubject.next(typeof this.$route.query.v === 'string' ? this.$route.query.v : '0');
        }
        if (this.$route.query.hasOwnProperty('p')) {
            this.currentPageSubject.next(
                typeof this.$route.query.p === 'string' && /^[-+]?(\d+)$/.test(this.$route.query.p)
                    ? parseInt(this.$route.query.p)
                    : 1,
            );
        }
        const selection: SignPropertySelection = {
            shapeLeft: this.$route.query.hasOwnProperty('sl') ? '' + this.$route.query.sl : '',
            shapeRight: this.$route.query.hasOwnProperty('sr') ? '' + this.$route.query.sr : '',
            gestureLeft: this.$route.query.hasOwnProperty('gl') ? '' + this.$route.query.gl : '',
            gestureRight: this.$route.query.hasOwnProperty('gr') ? '' + this.$route.query.gr : '',
            location: this.$route.query.hasOwnProperty('lc') ? '' + this.$route.query.lc : '',
            oralComponent: this.$route.query.hasOwnProperty('oc') ? '' + this.$route.query.oc : '',
        };
        this.signPropertySelectionSubject.next(selection);
    }

    private mergeRouteQuery(query: any) {
        const nextQuery = {
            ...this.$route.query,
            ...query,
        };
        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete nextQuery[key];
            }
        });
        this.$router
            .replace({
                ...this.$router.currentRoute,
                name: this.$router.currentRoute.name!,
                query: nextQuery,
            })
            .catch(() => {
                // ignored
            });
    }
}
