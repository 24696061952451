
import { Component, Prop, Vue } from 'vue-property-decorator';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import FormattedGlossName from '@/components/FormattedGlossName.vue';

@Component({
    components: {
        FormattedGlossName,
    },
})
export default class GlossTextSearchInput extends Vue {
    @Prop(Boolean) public readonly loading!: boolean;
    @Prop({ type: String, default: '' }) public readonly value!: string;
    @Prop({ type: Array, default: null }) public readonly results!: GlossSearchResult[] | null;

    public hasFocus: boolean = false;

    public get hasResults(): boolean {
        return this.results ? this.results.length > 0 : false;
    }

    public onInput(event: any): void {
        this.$emit('input', event.target.value || '', event);
    }

    public onSubmit(event: any): void {
        this.$emit('submit', event.target.value || '', event);
    }
}
