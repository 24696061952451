
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { BehaviorSubject, combineLatest, from as observableFrom, of as observableOf, Subscription, zip } from 'rxjs';
import { concatAll, distinctUntilChanged, first, last, map, switchMap } from 'rxjs/operators';
import GeneralScaffold from '../GeneralScaffold.vue';
import SearchOrderBySelector from '../SearchOrderBySelector.vue';
import GlobalViewModel from '../../state/GlobalViewModel';
import GlossRepository from '../../repositories/GlossRepository';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import Resource from '@/repositories/Resource';
import Pagination from '@/components/Pagination.vue';
import MediaFileThumbnail from '@/components/MediaFileThumbnail.vue';
import SignApiPage from '@/repositories/data/SignApiPage';
import { SortDirection } from '@/repositories/data/Pageable';

@Component({
    components: {
        GeneralScaffold,
        SearchOrderBySelector,
        Pagination,
        MediaFileThumbnail,
    },
})
export default class FavouritesScreen extends Vue {
    public searchResults: GlossSearchResult[] = [];
    public searchLoading: boolean = false;
    public searchResultsTotal: number = 0;
    public totalPageCount = 0;
    public selectAllChecked = false;

    private orderingSubject = new BehaviorSubject<string>('name');
    private currentPageSubject = new BehaviorSubject<number>(1);
    private deleteActionSubject = new BehaviorSubject<Resource<boolean>>(Resource.success(false));
    private paginationSize = 25;
    private favouritesSubscription = new Subscription();
    private deletionSubscription = new Subscription();
    private checkedGlossIds: string[] = [];
    @Inject() private readonly globalViewModel!: GlobalViewModel;
    @Inject() private readonly glossRepository!: GlossRepository;

    public created(): void {
        this.updateFromRouteQuery(true);
        this.favouritesSubscription = combineLatest([
            this.globalViewModel.verifiedUser,
            this.currentPageSubject.pipe(distinctUntilChanged()),
            this.orderingSubject.pipe(distinctUntilChanged()),
            this.deleteActionSubject,
        ])
            .pipe(
                switchMap(([user, currentPage, orderBy, deleteAction]) =>
                    deleteAction.isLoading
                        ? observableOf(Resource.loading<SignApiPage<GlossSearchResult>>(null))
                        : this.glossRepository.findGlossesByFavourite(null, {
                              page: currentPage - 1,
                              size: this.paginationSize,
                              sort:
                                  orderBy === 'name'
                                      ? [{ property: 'name', direction: SortDirection.ASC }]
                                      : [{ property: 'createdAt', direction: SortDirection.DESC }],
                          }),
                ),
            )
            .subscribe(glossPageResource => {
                if (glossPageResource.isSuccess) {
                    if (glossPageResource.hasData()) {
                        this.searchResults = glossPageResource.data.content;
                        this.searchResultsTotal = glossPageResource.data.totalElements;
                        this.totalPageCount = glossPageResource.data.totalPages;
                        this.$root.$emit('readyToScroll');
                    } else {
                        this.searchResults = [];
                        this.searchResultsTotal = 0;
                        this.totalPageCount = 0;
                    }
                }
                this.searchLoading = glossPageResource.isLoading;
            });
    }

    public beforeDestroy(): void {
        this.deletionSubscription.unsubscribe();
        this.favouritesSubscription.unsubscribe();
    }

    public onSearchTextQuery(search: string): void {
        this.$router.push({ name: 'search', query: { q: search } });
    }

    @Watch('selectAllChecked', { immediate: true })
    public onSelectAllCheckedChanged(value: boolean): void {
        if (value) {
            this.checkedGlossIds = this.searchResults.map(searchResult => searchResult.id);
        } else {
            this.checkedGlossIds = [];
        }
    }

    @Watch('$route')
    public onRouteChanged() {
        this.updateFromRouteQuery(false);
    }

    public onShowGloss(gloss: GlossSearchResult): void {
        this.$router.push({ name: 'gloss-details', params: { id: gloss.id } });
    }

    public onDeleteSelection(): void {
        if (!this.searchLoading) {
            this.deletionSubscription.unsubscribe();
            this.deleteActionSubject.next(Resource.loading<boolean>(null));
            observableFrom(this.checkedGlossIds)
                .pipe(
                    map(glossId =>
                        zip(this.globalViewModel.verifiedUser.pipe(first()), observableOf(glossId)).pipe(
                            switchMap(([user, id]) => this.glossRepository.setAsFavourite(id, false)),
                        ),
                    ),
                    concatAll(),
                    last(),
                )
                .subscribe(this.deleteActionSubject);
        }
    }

    public onDeleteFavourite(glossId: string): void {
        if (!this.searchLoading) {
            this.deletionSubscription.unsubscribe();
            this.deletionSubscription = zip(this.globalViewModel.verifiedUser.pipe(first()), observableOf(glossId))
                .pipe(switchMap(([user, id]) => this.glossRepository.setAsFavourite(id, false)))
                .subscribe(this.deleteActionSubject);
        }
    }

    public onPageSelected(page: number) {
        this.currentPageSubject.next(page);
        this.mergeRouteQuery({ p: page.toString(10) });
    }

    public onOrderByChanged(orderBy: string) {
        this.orderingSubject.next(orderBy);
        this.mergeRouteQuery({ o: orderBy === 'createdAt' ? 'recent' : null });
    }

    private updateFromRouteQuery(initial: boolean): void {
        if (this.$route.query.hasOwnProperty('o')) {
            this.orderingSubject.next(this.$route.query.o === 'recent' ? 'createdAt' : 'name');
        }
        if (this.$route.query.hasOwnProperty('p')) {
            this.currentPageSubject.next(
                typeof this.$route.query.p === 'string' && /^[-+]?(\d+)$/.test(this.$route.query.p)
                    ? parseInt(this.$route.query.p)
                    : 1,
            );
        }
    }

    private mergeRouteQuery(query: any) {
        const nextQuery = {
            ...this.$route.query,
            ...query,
        };
        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete nextQuery[key];
            }
        });
        this.$router
            .replace({
                ...this.$router.currentRoute,
                name: this.$router.currentRoute.name!,
                query: nextQuery,
            })
            .catch(() => {
                // ignored
            });
    }
}
