
import { Component, Prop, Vue } from 'vue-property-decorator';
import UsageExample from '@/repositories/data/UsageExample';
import { GestureFilmType } from '@/repositories/data/Phrase';
import { UsageExampleType } from '@/repositories/data/UsageExample';
import Variant from '@/repositories/data/Variant';
import MediaFile from '@/repositories/data/MediaFile';

@Component
export default class GlossVariantUsageExampleItem extends Vue {
    @Prop({ type: Object, required: true }) public readonly example!: UsageExample;
    @Prop({ default: null }) public readonly activeFilm!: MediaFile | null;

    get text(): string {
        let fullText = '';
        if (this.example.text) {
            fullText = fullText + this.example.text;
        }
        if (this.example.phrase && this.example.phrase.description) {
            fullText = fullText + this.example.phrase.description;
        }
        return fullText;
    }

    get label(): string {
        const filmType = this.example && this.example.phrase ? this.example.phrase.filmType : null;

        if (this.example && this.example.prefix === UsageExampleType.NotIn) {
            return 'X';
        }

        if (filmType) {
            switch (filmType) {
                case GestureFilmType.DutchSignLanguage:
                    return 'NGT';
                case GestureFilmType.DutchWithSigns:
                    return 'NmG';
            }
        }

        return '';
    }

    get className(): string | object {
        const filmType = this.example && this.example.phrase ? this.example.phrase.filmType : null;
        const filmId =
            this.example && this.example.phrase && this.example.phrase.film ? this.example.phrase.film.id : null;
        const prefix = this.example ? this.example.prefix : null;
        return {
            'has-video': filmId !== null,
            'is-ngt': filmType === GestureFilmType.DutchSignLanguage,
            'is-nmg': filmType === GestureFilmType.DutchWithSigns,
            'is-not-in': prefix === UsageExampleType.NotIn,
            'is-for-example': prefix === UsageExampleType.ForExample,
            'is-also-in': prefix === UsageExampleType.AlsoIn,
            playing: this.activeFilm && filmId === this.activeFilm.id,
        };
    }

    public onLabelClick(event: any): void {
        this.$emit('select', this.example, event);
    }

    public onReferenceClicked(variant: Variant): void {
        this.$emit('referenceSelected', variant);
    }
}
