import { AsyncSubject, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import { Loader } from '@googlemaps/js-api-loader';

export default class MapService {
    private _mapLibraryLoaderSubject: AsyncSubject<google.maps.MapsLibrary>;
    private _geocoderLoaderSubject: AsyncSubject<google.maps.Geocoder>;
    private _advancedMarkerLoaderSubject: AsyncSubject<google.maps.MarkerLibrary>;
    private readonly _googleMapsId: string;
    private _mapInstanceCache: Map<string, google.maps.Map> = new Map<string, google.maps.Map>();

    constructor(googleMapsApiKey: string, googleMapsId: string) {
        this._googleMapsId = googleMapsId;
        const loader = new Loader({
            apiKey: googleMapsApiKey,
            version: 'weekly',
            libraries: ['places'], // TODO: Determine if necessary
        });

        this._mapLibraryLoaderSubject = new AsyncSubject<google.maps.MapsLibrary>();
        fromPromise(loader.importLibrary('maps')).subscribe(mapLibrary => {
            this._mapLibraryLoaderSubject.next(mapLibrary as google.maps.MapsLibrary);
            this._mapLibraryLoaderSubject.complete();
        });

        this._geocoderLoaderSubject = new AsyncSubject<google.maps.Geocoder>();
        fromPromise(loader.importLibrary('geocoding')).subscribe(geocoder => {
            const { Geocoder } = geocoder as google.maps.GeocodingLibrary;
            this._geocoderLoaderSubject.next(new Geocoder());
            this._geocoderLoaderSubject.complete();
        });

        this._advancedMarkerLoaderSubject = new AsyncSubject<google.maps.MarkerLibrary>();
        fromPromise(google.maps.importLibrary('marker')).subscribe(marker => {
            this._advancedMarkerLoaderSubject.next(marker as google.maps.MarkerLibrary);
            this._advancedMarkerLoaderSubject.complete();
        });
    }

    public get googleMapsId(): string {
        return this._googleMapsId;
    }

    public createMap(
        key: string,
        element: HTMLElement,
        options: google.maps.MapOptions,
        keepOptions: boolean = false,
    ): Observable<google.maps.Map> {
        if (this._mapInstanceCache.has(key)) {
            const map = this._mapInstanceCache.get(key)!;
            if (!keepOptions) {
                map.setOptions(options);
            }
            element.replaceWith(map.getDiv());
            return of(map);
        }
        return this._mapLibraryLoaderSubject.pipe(
            map(({ Map }) => {
                return new Map(element, options);
            }),
            tap(map => {
                this._mapInstanceCache.set(key, map);
            }),
        );
    }

    public makeGeocodeRequest(request: google.maps.GeocoderRequest): Observable<google.maps.GeocoderResult[]> {
        return this._geocoderLoaderSubject.pipe(
            mergeMap(geocoder => {
                return geocoder.geocode(request);
            }),
            map((response: google.maps.GeocoderResponse) => {
                if (response && response.results && response.results.length > 0) {
                    return response.results;
                }
                return [];
            }),
            catchError((error: google.maps.GeocoderStatus) => {
                return of([]);
            }),
        );
    }

    public loadMarkerLibrary(): Observable<google.maps.MarkerLibrary> {
        return this._advancedMarkerLoaderSubject;
    }
}
