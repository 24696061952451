
import { Component, Vue } from 'vue-property-decorator';
import UserDisplay from '@/components/UserDisplay.vue';
import ErrorReportsFrame from '@/components/ErrorReportsFrame.vue';

@Component({
    components: {
        ErrorReportsFrame,
        UserDisplay,
    },
})
export default class SplashScaffold extends Vue {}
