import { Observable } from 'rxjs';
import GraphQlResponse from '@/repositories/api/GraphQlResponse';
import { map } from 'rxjs/operators';

export default function unwrapGraphQlResponse<T, K extends string>(
    observable: Observable<GraphQlResponse<T, K>>,
): Observable<T | null> {
    return observable.pipe(
        map(t => {
            let foundKey = null;
            for (const key in t.data) {
                if (t.data.hasOwnProperty(key)) {
                    foundKey = key;
                    break;
                }
            }
            return foundKey !== null ? t.data[foundKey] : null;
        }),
    );
}
