
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import MapService from '@/state/MapService';
import { Subscription } from 'rxjs';
import GeolocationFilter from '@/repositories/data/GeolocationFilter';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';

type ViewBounds = Pick<GeolocationFilter, 'minLatitude' | 'maxLatitude' | 'minLongitude' | 'maxLongitude'>;
type GeoGlossSearchResult = GlossSearchResult & { latitude: number; longitude: number };

interface SearchResultsByMapLayer {
    none: GeoGlossSearchResult[];
    country: GeoGlossSearchResult[];
    administrative_area_level_1: GeoGlossSearchResult[];
    administrative_area_level_2: GeoGlossSearchResult[];
}

const featureMapLayers = ['country', 'administrative_area_level_1', 'administrative_area_level_2'];
const mapLayerStyles: { [key: string]: google.maps.FeatureStyleOptions | null } = {
    none: null,
    country: {
        strokeColor: '#F6A43E',
        strokeOpacity: 1.0,
        strokeWeight: 2.0,
        fillColor: '#F2F7FB',
        fillOpacity: 0.3,
    },
    administrative_area_level_1: {
        strokeColor: '#00549A',
        strokeOpacity: 0.5,
        strokeWeight: 2.0,
        fillColor: '#F6A43E',
        fillOpacity: 0.25,
    },
    administrative_area_level_2: {
        strokeColor: '#00549A',
        strokeOpacity: 1.0,
        strokeWeight: 2.0,
        fillColor: '#00549A',
        fillOpacity: 0.5,
    },
    highlight: {
        strokeColor: '#800080',
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
    },
};

const mapPinColors: { [key: string]: string | null } = {
    default: null,
    water: '#51d9f5',
    mountain: '#d6965e',
};

function isGeoGlossSearchResult(result: GlossSearchResult): result is GeoGlossSearchResult {
    return result.latitude !== null && result.longitude !== null;
}

@Component
export default class GeolocationFilterSelector extends Vue {
    public showLegend: boolean = true;
    public showLayerSelectionDropdown: boolean = false;
    public mapLayers: string[] = ['none', 'country', 'administrative_area_level_1', 'administrative_area_level_2'];
    public viewBounds: ViewBounds = { minLatitude: 0, maxLatitude: 0, minLongitude: 0, maxLongitude: 0 };

    @Inject()
    private mapService!: MapService;

    @Prop({ required: false, type: Array, default: () => [] })
    private searchResults!: GlossSearchResult[];

    @Prop({ required: false, type: Object, default: null })
    private latestQuickSearchResult: GlossSearchResult | null = null;

    private defaultLatitude: number = 52.132633;
    private defaultLongitude: number = 5.291266;
    private mapLoadingSubscription: Subscription = new Subscription();
    private markerLoadingSubscription: Subscription = new Subscription();
    private map: google.maps.Map | null = null;
    private markerLibrary: google.maps.MarkerLibrary | null = null;
    private markersByResultId: { [key: string]: google.maps.marker.AdvancedMarkerElement } = {};
    private mapEventListenersByResultId: { [key: string]: google.maps.MapsEventListener[] } = {};
    private mapEventListeners: google.maps.MapsEventListener[] = [];
    private infoWindow: google.maps.InfoWindow | null = null;
    private infoWindowPriority: number = 0;
    private clickedResultTimeout: any = null;

    public get geolocationFilter(): GeolocationFilter {
        return {
            ...this.viewBounds,
            mapLayers: [...this.mapLayers],
        };
    }

    public get searchResultsByMapLayer(): SearchResultsByMapLayer {
        const resultsByMapLayer: SearchResultsByMapLayer = {
            none: [],
            country: [],
            administrative_area_level_1: [],
            administrative_area_level_2: [],
        };
        if (!this.map || !this.markerLibrary) {
            return resultsByMapLayer;
        }
        for (const result of this.searchResults) {
            if (!isGeoGlossSearchResult(result)) {
                continue;
            }
            if (result.mapLayer === null) {
                resultsByMapLayer.none.push(result);
            } else if (result.placeId !== null && this.mapLayers.includes(result.mapLayer)) {
                (resultsByMapLayer as any)[result.mapLayer].push(result);
            }
        }
        return resultsByMapLayer;
    }

    public mounted(): void {
        this.showLegend = localStorage.getItem('geolocationFilterSelector.showLegend') !== 'false';
        this.mapLoadingSubscription = this.mapService
            .createMap(
                'geolocationFilter',
                this.$refs.mapContainer as HTMLElement,
                {
                    zoom: 8,
                    center: {
                        lat: this.defaultLatitude,
                        lng: this.defaultLongitude,
                    },
                    mapId: this.mapService.googleMapsId,
                    mapTypeId: 'roadmap',
                    disableDefaultUI: true,
                    fullscreenControl: true,
                    zoomControl: true,
                },
                true,
            )
            .subscribe(map => {
                this.map = map;
                this.setupMapEventListeners();
                this.onMapBoundsChanged();
            });
        this.markerLoadingSubscription = this.mapService.loadMarkerLibrary().subscribe(markerLibrary => {
            this.markerLibrary = markerLibrary;
        });
    }

    public onResultClicked(result: GlossSearchResult): void {
        if (this.clickedResultTimeout === null) {
            this.clickedResultTimeout = setTimeout(() => {
                this.clickedResultTimeout = null;
            }, 1000);
            this.$emit('result-clicked', result);
        }
    }

    public onResultHover(
        result: GlossSearchResult,
        marker: google.maps.marker.AdvancedMarkerElement | null,
        leave: boolean,
    ): void {
        if (this.infoWindowPriority > (result.mapPriority || 0)) {
            return;
        }
        if (this.infoWindow) {
            this.infoWindow.close();
            this.infoWindow.setContent(result.name);
            this.infoWindow.setPosition({
                lat: result.latitude!,
                lng: result.longitude!,
            });
        } else {
            this.infoWindow = new google.maps.InfoWindow({
                content: result.name,
                position: {
                    lat: result.latitude!,
                    lng: result.longitude!,
                },
            });
        }
        this.infoWindowPriority = 0;
        if (!leave) {
            this.infoWindowPriority = result.mapPriority || 0;
            this.infoWindow.open(this.map, marker);
        }
    }

    public onMapBoundsChanged(): void {
        if (this.map) {
            const bounds = this.map.getBounds();
            if (bounds) {
                const northEast = bounds.getNorthEast();
                const southWest = bounds.getSouthWest();
                this.viewBounds = {
                    minLatitude: southWest.lat(),
                    maxLatitude: northEast.lat(),
                    minLongitude: southWest.lng(),
                    maxLongitude: northEast.lng(),
                };
            }
        }
    }

    @Watch('latestQuickSearchResult', { immediate: true })
    public onLatestQuickSearchResultChanged(): void {
        if (
            this.map &&
            this.latestQuickSearchResult &&
            this.latestQuickSearchResult.latitude !== null &&
            this.latestQuickSearchResult.longitude !== null
        ) {
            this.map.setCenter({
                lat: this.latestQuickSearchResult.latitude,
                lng: this.latestQuickSearchResult.longitude,
            });
            const zoomLevel =
                this.latestQuickSearchResult.mapLayer === 'country'
                    ? 6
                    : this.latestQuickSearchResult.mapLayer === 'administrative_area_level_1'
                    ? 8
                    : this.latestQuickSearchResult.mapLayer === 'administrative_area_level_2'
                    ? 10
                    : 11;
            this.map.setZoom(zoomLevel);
        }
        this.updateFeatureLayers();
    }

    @Watch('geolocationFilter', { immediate: true })
    public onGeolocationFilterChanged(): void {
        this.$emit('filter-changed', this.geolocationFilter);
    }

    @Watch('searchResultsByMapLayer', { immediate: true })
    public onSearchResultsByMapLayerChanged(): void {
        if (!this.map || !this.markerLibrary) {
            return;
        }
        const { AdvancedMarkerElement, PinElement } = this.markerLibrary;
        const existingMarkersResultsIds = Object.keys(this.markersByResultId);
        const activeMarkersResultsIds = this.searchResultsByMapLayer.none.map(result => '' + result.id);
        const removedMarkersResultsIds = existingMarkersResultsIds.filter(
            resultId => !activeMarkersResultsIds.includes(resultId),
        );
        removedMarkersResultsIds.forEach(resultId => {
            this.markersByResultId[resultId].content!.removeEventListener('mouseenter', null);
            this.markersByResultId[resultId].content!.removeEventListener('mouseleave', null);
            this.markersByResultId[resultId].map = null;
            delete this.markersByResultId[resultId];
            this.mapEventListenersByResultId[resultId].forEach(listener => listener.remove());
            delete this.mapEventListenersByResultId[resultId];
        });
        this.searchResultsByMapLayer.none
            .filter(result => !existingMarkersResultsIds.includes('' + result.id))
            .forEach(result => {
                const resultId = '' + result.id;
                const marker = new AdvancedMarkerElement({
                    position: {
                        lat: result.latitude,
                        lng: result.longitude,
                    },
                    map: this.map,
                    title: result.name,
                    content: new PinElement({
                        background: mapPinColors[result.mapPinType || 'default'] || null,
                    }).element,
                });
                this.markersByResultId[resultId] = marker;
                this.mapEventListenersByResultId[resultId] = [
                    marker.addListener('click', () => {
                        this.onResultClicked(result);
                    }),
                ];
                marker.content!.addEventListener('mouseenter', () => {
                    this.onResultHover(result, marker, false);
                });
                marker.content!.addEventListener('mouseleave', () => {
                    this.onResultHover(result, marker, true);
                });
            });
        this.updateFeatureLayers();
    }

    @Watch('showLegend')
    public onShowLegendChanged(): void {
        localStorage.setItem('geolocationFilterSelector.showLegend', this.showLegend ? 'true' : 'false');
    }

    public beforeDestroy(): void {
        if (this.clickedResultTimeout) {
            clearTimeout(this.clickedResultTimeout);
        }
        if (this.map) {
            this.map.unbind('bounds_changed');
            for (const mapLayer of featureMapLayers) {
                const featureLayer = this.map.getFeatureLayer(mapLayer.toUpperCase() as google.maps.FeatureType);
                if (featureLayer.isAvailable) {
                    featureLayer.style = null;
                }
            }
        }
        this.cleanUpMapEventListeners();
        if (this.infoWindow) {
            this.infoWindow.close();
        }
        this.cleanUpMarkers();
        this.mapLoadingSubscription.unsubscribe();
        this.markerLoadingSubscription.unsubscribe();
    }

    private updateFeatureLayers(): void {
        if (!this.map) {
            return;
        }
        const highlightedPlaceId = this.latestQuickSearchResult?.placeId || null;
        for (const mapLayer of featureMapLayers) {
            const featureLayer = this.map.getFeatureLayer(mapLayer.toUpperCase() as google.maps.FeatureType);
            if (featureLayer.isAvailable) {
                const mapLayerPlaceIds = (this.searchResultsByMapLayer as any)[mapLayer].map(
                    (result: GeoGlossSearchResult) => result.placeId,
                );
                if (mapLayerPlaceIds.length > 0) {
                    featureLayer.style = (options: google.maps.FeatureStyleFunctionOptions) => {
                        const placeId = (options.feature as google.maps.PlaceFeature).placeId;
                        if (placeId && mapLayerPlaceIds.includes(placeId)) {
                            if (placeId === highlightedPlaceId) {
                                return {
                                    ...mapLayerStyles[mapLayer],
                                    ...mapLayerStyles.highlight,
                                };
                            }
                            return mapLayerStyles[mapLayer];
                        }
                        return null;
                    };
                } else {
                    featureLayer.style = null;
                }
            }
        }
    }

    private setupMapEventListeners(): void {
        if (!this.map) {
            return;
        }
        featureMapLayers.forEach(mapLayer => {
            const featureLayer = this.map!.getFeatureLayer(mapLayer.toUpperCase() as google.maps.FeatureType);
            this.mapEventListeners.push(
                featureLayer.addListener('click', (event: google.maps.FeatureMouseEvent) => {
                    const feature = event.features[0] || null;
                    const placeId = (feature as any).placeId || null;
                    if (placeId) {
                        const result = this.searchResults.find(searchResult => searchResult.placeId === placeId);
                        if (result) {
                            this.onResultClicked(result);
                        }
                    }
                }),
            );
            this.mapEventListeners.push(
                featureLayer.addListener('mousemove', (event: google.maps.FeatureMouseEvent) => {
                    const feature = event.features[0] || null;
                    const placeId = (feature as any).placeId || null;
                    if (placeId) {
                        const result = this.searchResults.find(searchResult => searchResult.placeId === placeId);
                        if (result) {
                            this.onResultHover(result, null, false);
                        }
                    }
                }),
            );
        });
        this.mapEventListeners.push(
            this.map!.addListener('mousemove', () => {
                if (this.infoWindowPriority !== 4) {
                    this.infoWindow?.close();
                    this.infoWindowPriority = 0;
                }
            }),
        );
        this.mapEventListeners.push(this.map.addListener('bounds_changed', this.onMapBoundsChanged));
    }

    private cleanUpMapEventListeners(): void {
        this.mapEventListeners.forEach(listener => listener.remove());
        this.mapEventListeners = [];
    }

    private cleanUpMarkers(): void {
        const markerResultsIds = Object.keys(this.markersByResultId);
        markerResultsIds.forEach(resultId => {
            this.markersByResultId[resultId].content!.removeEventListener('mouseenter', null);
            this.markersByResultId[resultId].content!.removeEventListener('mouseleave', null);
            this.markersByResultId[resultId].map = null;
            delete this.markersByResultId[resultId];
            this.mapEventListenersByResultId[resultId].forEach(listener => listener.remove());
            delete this.mapEventListenersByResultId[resultId];
        });
    }
}
