var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ow-gloss-preview-container",class:{
        'plyr--full-ui': _vm.imitatePlayer,
        'plyr--video': _vm.imitatePlayer,
        'plyr--html5': _vm.imitatePlayer,
        'plyr--paused': _vm.imitatePlayer,
        'plyr--stopped': _vm.imitatePlayer,
    }},[_c('img',{attrs:{"src":_vm.thumbnailUrl,"alt":_vm.caption}}),(_vm.imitatePlayer)?_c('button',{staticClass:"plyr__control plyr__control--overlaid",attrs:{"type":"button","aria-label":"Play"}},[_c('svg',{attrs:{"role":"presentation","focusable":"false"}},[_c('use',{attrs:{"xlink:href":require("../assets/images/plyr.svg") + "#plyr-play"}})]),_c('span',{staticClass:"plyr__sr-only"},[_vm._v("Play")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }