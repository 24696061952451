
import { Component, Vue } from 'vue-property-decorator';
import SplashScaffold from '@/components/SplashScaffold.vue';

@Component({
    components: {
        SplashScaffold,
    },
})
export default class NotFoundScreen extends Vue {}
