import GlossFilter from '@/repositories/data/GlossFilter';
import ReferenceData from '@/repositories/data/ReferenceData';
import User from '@/repositories/data/User';
import { Observable } from 'rxjs';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import Gloss from '@/repositories/data/Gloss';
import GlossOrdering, { OrderMode } from '@/repositories/data/GlossOrdering';
import stringifyObject from 'stringify-object';
import Module from '@/repositories/data/Module';
import HttpClient from '@/util/http/HttpClient';
import GraphQlResponse from '@/repositories/api/GraphQlResponse';
import GraphQlConnection from '@/repositories/api/GraphQlConnection';

export default class GraphQlApi {
    private readonly client: HttpClient;
    private readonly baseUrl: string;

    constructor(client: HttpClient, apiEndpoint: string) {
        this.client = client;
        this.baseUrl = apiEndpoint + '/graphql/';
    }

    public getUser(): Observable<GraphQlResponse<User, 'user'>> {
        const queryBody = {
            operationName: 'GetUser',
            query: `
                query GetUser {
                    user {
                        name
                    }
                }
            `.replace(' ', ''),
            variables: {},
        };
        return this.client.post(this.baseUrl, queryBody);
    }

    public getGlossesPage(
        first: number,
        after: string | null,
        where: GlossFilter,
        orderBy: GlossOrdering | null,
        moduleId: string | null,
    ): Observable<GraphQlResponse<GraphQlConnection<GlossSearchResult>, 'glossesConnection'>> {
        const whereClause = stringifyObject(where, { singleQuotes: false }).replace('\\', '\\\\');
        const afterLine = after !== null ? `after: "${after}",` : '';
        const orderByLine = orderBy !== null ? 'orderBy: ' + this.stringifyGlossOrdering(orderBy) : '';
        const moduleLine = moduleId !== null ? 'module: "' + moduleId + '"' : '';
        const queryBody = {
            operationName: 'GetGlossesCn',
            query: `
                    query GetGlossesCn {
                        glossesConnection(
                            product: OW,
                            first: ${first}, ${afterLine}
                            where: ${whereClause}
                            ${orderByLine}
                            ${moduleLine}
                        ) {
                            edges {
                                node {
                                    id
                                    name
                                    keywords
                                    defaultFilm { id }
                                }
                            }
                            pageInfo {
                                endCursor
                                totalNodeCount
                                totalPageCount
                            }
                        }
                    }
                `.replace(' ', ''),
            variables: {},
        };
        return this.client.post(this.baseUrl, queryBody);
    }

    public getGloss(where: GlossFilter, moduleId: string | null): Observable<GraphQlResponse<Gloss, 'gloss'>> {
        const whereClause = stringifyObject(where, { singleQuotes: false }).replace('\\', '\\\\');
        const moduleLine = moduleId !== null ? 'module: "' + moduleId + '"' : '';
        const queryBody = {
            operationName: 'GetGloss',
            query: `
                    query GetGloss {
                        gloss(
                            product: OW,
                            where: ${whereClause}
                            ${moduleLine}
                        ) {
                            ...glossSearchResult
                            mapImage { id }
                            favoured
                            explanations {
                                film { ...film }
                                text
                                category
                                priority
                            }
                            variants {
                                ...variantRef
                                category { id, label, priority }
                                otherFormReferences { ...variantRef }
                                seeAlsoReferences { ...variantRef }
                                signDefinitions {
                                    leftHandShape { ...signShape }
                                    rightHandShape { ...signShape }
                                    nextLeftHandShape { ...signShape }
                                    nextRightHandShape { ...signShape }
                                    gestureLocation { id }
                                    film { ...film }
                                }
                                usageExamples {
                                    priority
                                    prefix
                                    text
                                    phrase {
                                        id
                                        #text
                                        description
                                        variantCategory { id }
                                        filmType
                                        film { ...film }
                                    }
                                    glossVariantReference {
                                        id
                                        referenceName
                                        gloss {
                                            id
                                        }
                                        category {
                                            id
                                        }
                                    }
                                }
                                grammaticalInformation {
                                    text
                                    #film { ...film }
                                }
                            }
                            photos {
                                id
                            }
                            pictograms {
                                id
                            }
                        }
                    }
                    fragment film on MediaFile {
                        id
                        height
                        width
                    }
                    fragment glossSearchResult on Gloss {
                        id
                        name
                        keywords
                        defaultFilm { ...film }
                        latitude
                        longitude
                        placeId
                        mapLayer
                        mapPriority
                        mapPinType
                    }
                    fragment variantRef on Variant {
                        id
                        referenceName
                        gloss {
                            ...glossSearchResult
                        }
                    }
                    fragment signShape on SignShape {
                        id
                        labelNl
                    }
                `.replace(' ', ''),
            variables: {},
        };
        return this.client.post(this.baseUrl, queryBody);
    }

    public setGlossFavoured(
        id: string,
        favourite: boolean,
    ): Observable<GraphQlResponse<boolean, 'setGlossAsFavourite'>> {
        const queryBody = {
            operationName: 'SetFavourite',
            query: `
                    mutation SetFavourite($id: ID, $favourite: Boolean) {
                        setGlossAsFavourite(
                            glossId: $id,
                            favourite: $favourite
                        ) {
                            favoured
                        }
                    }
                `.replace(' ', ''),
            variables: {
                id,
                favourite,
            },
        };
        return this.client.post(this.baseUrl, queryBody);
    }

    public getReferenceData(moduleId: string | null): Observable<GraphQlResponse<ReferenceData, 'referenceData'>> {
        const moduleLine = moduleId !== null ? 'module: "' + moduleId + '"' : '';
        const queryBody = {
            operationName: 'GetRefData',
            query: `
                    query GetRefData {
                        referenceData(
                            product: OW,
                            ${moduleLine}
                        ) {
                            signGestures {
                                id
                                labelNl
                                groupLabelNl
                            }
                            signLocations {
                                id
                                labelNl
                            }
                            signOralComponents {
                                id
                                labelNl
                            }
                            signShapes {
                                id
                                labelNl
                            }
                            themes {
                                id
                                label
                                parent {
                                    id
                                    label
                                }
                            }
                            variantCategories {
                                id
                                label
                            }
                            module {
                                id
                                name
                            }
                        }
                    }
                `.replace(' ', ''),
            variables: {},
        };
        return this.client.post(this.baseUrl, queryBody);
    }

    public getModules(
        first: number,
        after: string | null,
    ): Observable<GraphQlResponse<GraphQlConnection<Module>, 'modulesConnection'>> {
        const afterLine = after !== null ? `after: "${after}"` : '';
        const queryBody = {
            operationName: 'GetModulesCn',
            query: `
                    query GetModulesCn {
                        modulesConnection(product: OW, first: ${first}, ${afterLine}) {
                            edges {
                                node {
                                    id
                                    name
                                    banner {
                                        id
                                    }
                                }
                            }
                            pageInfo {
                                endCursor
                                totalNodeCount
                                totalPageCount
                            }
                        }
                    }
                `.replace('    ', ''),
            variables: {},
        };
        return this.client.post(this.baseUrl, queryBody);
    }

    private stringifyGlossOrdering(orderBy: GlossOrdering | null): string {
        if (orderBy === null) {
            return '{}';
        }

        const conditions: string[] = [];
        // we assume each prop is of type OrderMode
        for (const [key, value] of Object.entries(orderBy)) {
            conditions.push(`${key}: ${OrderMode[value]}`);
        }

        return `{${conditions.join(', ')}}`;
    }
}
