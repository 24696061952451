import HttpClient from '@/util/http/HttpClient';
import { Pageable } from '@/repositories/data/Pageable';
import { Observable } from 'rxjs';
import GlossSearchResult from '@/repositories/data/GlossSearchResult';
import SignApiPage from '@/repositories/data/SignApiPage';

export default class SignApi {
    private readonly client: HttpClient;
    private readonly baseUrl: string;

    constructor(client: HttpClient, apiEndpoint: string) {
        this.client = client;
        this.baseUrl = apiEndpoint + '/sign-api/';
    }

    public findGlossesByKeyword(
        keyword: string | null,
        anywhere: boolean,
        themeId: string | null,
        variantCategoryId: string | null,
        moduleId: string | null,
        pageable: Pageable,
    ): Observable<SignApiPage<GlossSearchResult>> {
        const params = this.buildPageableParams(pageable);
        if (keyword !== null) {
            params.keyword = keyword;
        }
        if (anywhere) {
            params.anywhere = 'true';
        }
        if (themeId !== null) {
            params.theme = themeId;
        }
        if (variantCategoryId !== null) {
            params.variant = variantCategoryId;
        }
        if (moduleId !== null) {
            params.module = moduleId;
        }
        return this.client.get('gloss/by-keyword', {
            baseUrl: this.baseUrl,
            params,
        });
    }

    public findGlossesByGeolocation(
        minLatitude: number,
        maxLatitude: number,
        minLongitude: number,
        maxLongitude: number,
        mapLayers: string[],
        themeId: string | null,
        variantCategoryId: string | null,
        moduleId: string | null,
        pageable: Pageable,
    ): Observable<SignApiPage<GlossSearchResult>> {
        const params = this.buildPageableParams(pageable);
        params.minLatitude = minLatitude;
        params.maxLatitude = maxLatitude;
        params.minLongitude = minLongitude;
        params.maxLongitude = maxLongitude;
        params.mapLayers = mapLayers.join(',');
        if (themeId !== null) {
            params.theme = themeId;
        }
        if (variantCategoryId !== null) {
            params.variant = variantCategoryId;
        }
        if (moduleId !== null) {
            params.module = moduleId;
        }
        return this.client.get('gloss/by-geolocation', {
            baseUrl: this.baseUrl,
            params,
        });
    }

    public findGlossesByParameter(
        shapeLeft: string | null,
        shapeRight: string | null,
        location: string | null,
        gestureLeft: string | null,
        gestureRight: string | null,
        oralComponent: string | null,
        themeId: string | null,
        variantCategoryId: string | null,
        moduleId: string | null,
        pageable: Pageable,
    ): Observable<SignApiPage<GlossSearchResult>> {
        const params = this.buildPageableParams(pageable);
        if (shapeLeft !== null) {
            params.shapeLeft = shapeLeft;
        }
        if (shapeRight !== null) {
            params.shapeRight = shapeRight;
        }
        if (location !== null) {
            params.location = location;
        }
        if (gestureLeft !== null) {
            params.gestureLeft = gestureLeft;
        }
        if (gestureRight !== null) {
            params.gestureRight = gestureRight;
        }
        if (oralComponent !== null) {
            params.oralComponent = oralComponent;
        }
        if (themeId !== null) {
            params.theme = themeId;
        }
        if (variantCategoryId !== null) {
            params.variant = variantCategoryId;
        }
        if (moduleId !== null) {
            params.module = moduleId;
        }
        return this.client.get('gloss/by-parameter', {
            baseUrl: this.baseUrl,
            params,
        });
    }

    public findGlossesByFavourite(
        moduleId: string | null,
        pageable: Pageable,
    ): Observable<SignApiPage<GlossSearchResult>> {
        const params = this.buildPageableParams(pageable);
        if (moduleId !== null) {
            params.module = moduleId;
        }
        return this.client.get('gloss/by-favourite', {
            baseUrl: this.baseUrl,
            params,
        });
    }

    protected buildPageableParams(pageable: Pageable) {
        const params: any = {
            page: pageable.page,
            size: pageable.size,
            sort: pageable.sort.map(t => t.property + ',' + t.direction).join(','),
        };
        return params;
    }
}
